/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: CONTACT FORM ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_contact-form {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }
}
