/* ------------------------------------------------------------------------------------------------------------------------ */
/* INSTAGRAM FEED --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.instagram-feed {
    @include flex-row;
    @include relative;

    &:before,
    &:after {

        @media screen and (min-width:769px) {
            content:"";
            flex-shrink:0;
            width:74px;
        }
    }

    .sbi {
        width:calc(100% - 148px) !important; max-width:map-get($widths, _base);
    }
        .sbi_carousel {
            position:static !important;
        }
            .sbi-owl-nav {
                @include flex-row;
                justify-content:space-between;
                align-items:center;

                @media screen and (min-width:769px) {
                    @include absolute;
                    top:50%; left:0; right:0;
                    height:0;
                    transform:translateY(-50%);
                }
                @media screen and (max-width:768px) {
                    justify-content:center;
                    padding-top:24px;
                }

                .sbi-owl-prev,
                .sbi-owl-next {
                    @include relative;
                    top:auto !important; left:auto !important; right:auto !important; bottom:auto !important;
                    margin:0; padding:0;
                    width:26px; height:48px;
                    background:none !important;
                    border:none !important;
                    box-shadow:none !important;
                    transform:none !important;

                    @media screen and (max-width:768px) {
                        zoom:.5;
                    }

                    svg {
                        display:none !important;
                    }

                    &:before,
                    &:after {
                        @include animate;
                        @include absolute;
                        content:"";
                        display:block;
                        left:-4px;
                        width:34px; height:2px;
                        background:map-get($colors, _01-01);
                    }
                    &:before {
                        top:35px;
                        transform:rotate(45deg);
                    }
                    &:after {
                        top:11px;
                        transform:rotate(-45deg);
                    }

                    @media screen and (min-width:769px) {
                        &:hover,
                        &:focus {

                            &:before,
                            &:after {
                                background:map-get($colors, _04-01);
                            }
                        }
                    }
                }
                .sbi-owl-prev {

                    @media screen and (max-width:768px) {
                        margin-right:24px;
                    }
                }
                .sbi-owl-next {
                    transform:rotate(180deg) !important;

                    @media screen and (max-width:768px) {
                        margin-left:24px;
                    }
                }
            }
}

#sb_instagram {

    .sbi_hover_top,
    .sbi_hover_bottom {
        padding:24px 32px !important;
        width:100%;
        font-size:map-get($font_sizes, _small);
        box-sizing:border-box;
    }

    .sbi_link .sbi_caption {
        line-height:map-get($line_heights, _small);
    }

    svg:not(:root).svg-inline--fa,
    svg:not(:root).svg-inline--fa {
        width:18px; height:18px;
    }

    .sbi_meta {
        @include flex-row;
        justify-content:center;
        align-items:center;
        line-height:1;

        span {
            @include flex-row;
            align-items:center;
            font-size:map-get($font_sizes, _xsmall);
        }
    }
}
