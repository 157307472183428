/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: TEXT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@media screen and (min-width:1221px) {
    .pb-block_text + .pb-block_contact-form > .pb-block_content {
        margin-top:-48px;
    }
}

@media screen and (max-width:768px) {
    .pb-block_text + .pb-block_colour-collection > .colour-collection {
        margin-top:-36px;
    }
}
