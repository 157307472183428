/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.footer {

    h4 {
        line-height:map-get($line_heights, _xsmall);
        font-size:map-get($font_sizes, _xsmall);
        text-transform:uppercase;
    }
}
    .footer-content > .bleed_96 {

        @media screen and (max-width:768px) {
            padding-top:48px; padding-bottom:48px;
        }
    }
        .footer-bottom {
            line-height:map-get($line_heights, _xsmall);
            font-size:map-get($font_sizes, _xsmall);

            @media screen and (max-width:768px) {
                padding-top:48px;
            }

            & > .column:not(:first-child) {

                @media screen and (max-width:768px) {
                    padding-top:18px;
                }
            }

            a {
                @include animate;
                color:map-get($colors, _01-01);
                text-decoration:underline;

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover,
                    &:not(:disabled):focus {
                        color:map-get($colors, _04-01);
                        text-decoration:none;
                    }
                }
            }
        }


/*
 * Contacts
 */

.footer-contacts_wrapper {

    @media screen and (max-width:768px) {
        padding-top:48px;
    }
}
    .footer-contacts {
        list-style:none;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }
        .footer-contacts_item {

            @media screen and (min-width:950px) {
                @include flex-row;
                line-height:1;
            }

            &:not(:first-child) {
                padding-top:12px;
            }

            h4 {

                @media screen and (min-width:769px) {
                    width:120px;
                }
            }

            a {
                @include animate;
                color:map-get($colors, _03-02);
                font-weight:700;

                @media screen and (min-width:769px) {
                    margin-top:-1px;

                    &:not(:disabled):hover,
                    &:not(:disabled):focus {
                        color:map-get($colors, _03-01);
                        text-decoration:underline;
                    }
                }
            }
        }


/*
 * Navigation
 */

.nav-footer {

    @media screen and (max-width:768px) {
        padding-top:48px;
    }
}
    .nav-footer_list {
        padding:16px 0;
        border-top:1px solid map-get($colors, _01-04); border-bottom:1px solid map-get($colors, _01-04);
        list-style:none;

        @media screen and (min-width:820px) {
            @include flex-row;
            justify-content:space-between;
        }
        @media screen and (max-width:768px) {
            padding:24px 0;
        }
    }
        .nav-footer_item {

            @media screen and (max-width:820px) {
                text-align:center;
            }

            &:not(:last-child) {

                @media screen and (min-width:820px) {
                    margin-right:32px;
                }
                @media screen and (max-width:768px) {
                    margin-bottom:12px;
                }
            }
        }
            .nav-footer_link {
                @include animate;
                line-height:map-get($line_heights, _xsmall);
                color:map-get($colors, _01-01);
                font-size:map-get($font_sizes, _xsmall);
                font-weight:700;
                text-transform:uppercase;

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover,
                    &:not(:disabled):focus {
                        color:map-get($colors, _04-01);
                    }
                }
            }
