/* ------------------------------------------------------------------------------------------------------------------------ */
/* AFFILIATE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Tabs
 */

.nav-tab-wrapper {
    @include flex-row;
    padding:0 0 12px;
    border-bottom:2px solid map-get($colors, _01-04);

    .nav-tab {
        @include animate;
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        float:none;
        margin:0 32px -14px 0; padding:0 0 12px;
        color:map-get($colors, _01-01);
        font-weight:700;
        border:none; border-bottom:2px solid transparent;

        &:not(:first-child) {
            display: none;
        }

        &.nav-tab-active {
            border-color:map-get($colors, _04-01);
        }

        @media screen and (min-width:769px) {
            &:not(.disabled):not(:disabled):hover,
            &:not(.disabled):not(:disabled):focus {
                border-color:map-get($colors, _04-01);
            }
        }
    }
}


/*
 * From / to Datepicker
 */

#afwc_top_row_container {
    @include flex-row;
    justify-content:flex-end;
    margin-top:0; padding-top:24px;
}
    #afwc_date_range_container {
        @include flex-row;
        float:none;
        margin:0; padding:12px;
        width:auto; height:auto;
        line-height:map-get($line_heights, _small);
        font-size:map-get($font_sizes, _small);
        border:2px solid map-get($colors, _01-04);
        border-radius:6px;

        @media screen and (max-width:768px) {
            width:100%;
        }
    }
        #afwc_from,
        #afwc_to {
            padding:0;
            width:120px;
            color:map-get($colors, _01-01);
        }


/*
 * Stats
 */

#afwc_kpis_container {
    display:block;
    padding-top:48px;

    @media screen and (max-width:768px) {
        padding-top:24px;
    }

    .afwc_kpis_inner_container {
        display:block;
        margin:-2px 0 0;

        @media screen and (min-width:769px) {
            @include flex-row;
            flex-wrap:wrap;
            justify-content:space-between;
        }
    }
}
    .afwc_kpi {
        @include flex-row;
        float:none;
        margin:2px 0 0 !important;
        width:calc(33.33% - 1px); min-width:0; height:auto;
        border:1px solid map-get($colors, _01-04);
        box-sizing:border-box;

        @media screen and (max-width:768px) {
            width:100%;
        }

        .container_parent_left {
            float:none;
            padding:24px;
            width:48px; height:auto;
            border-right:1px solid map-get($colors, _01-04);
        }
            .afwc_kpis_icon_container {
                width:auto; height:auto;
                border:none;
            }
        .afwc_kpis_data {
            flex:1 1 auto;
            float:none;
            padding:24px;
            width:auto; height:auto;
        }
    }


/*
 * Table
 */

#afwc_dashboard_wrapper .afwc-table-header {
    @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
    padding:48px 0 0;
    font-weight:700;

    @media screen and (max-width:768px) {
        padding-top:24px;
    }

    & + table {

        @media screen and (min-width:769px) {
            margin-top:24px;
        }

        thead th {
            font-size:map-get($font_sizes, _base);
        }

        tbody {
            overflow-y:unset;
            max-height:none;
        }

        tr {

            @media screen and (max-width:768px) {
                flex-direction:column;

                &:last-child {
                    padding-bottom:16px;
                    border-bottom:1px solid map-get($colors, _01-04);
                }
            }
        }

        td:not([colspan="3"]):not([colspan="4"]) {

            @media screen and (max-width:768px) {
                @include flex-row;

                &:before {
                    content:attr(data-title);
                    width:120px;
                    font-weight:700;
                }
            }

            .circle {
                margin:0;
            }
        }

        [colspan="3"],
        [colspan="4"] {
            text-align:left;
        }
        [data-title] {

            @media screen and (max-width:768px) {
                align-items:center;
                text-align:left;
            }
        }
    }
}


/*
 * Profile
 */

#afwc_resources_wrapper {
    display:block;
    margin:0; padding-top:48px;

    @media screen and (max-width:768px) {
        padding-top:24px;
    }
}
    #afwc_referral_url_container {
        margin-top:0;

        p:last-child {
            padding-top:24px;
        }
    }

        #afwc_id_change_wrap {
            @include flex-row;
            align-items:center;

            code {
                padding:0 12px 0 6px;
                font-size:map-get($font_sizes, _base);
                font-weight:700;
                cursor:default;
            }
        }

        #afwc_resources_wrapper #afwc_id_save_wrap {
            @include flex-row;
            align-items:center;

            input {
                @include woo-input-text;
                align-self:normal;
                margin:0 6px 0 24px;
                height:auto;
            }
            button {
                @include woo-button;
            }
        }

        #afwc_save_id_loader + p {
            padding-top:24px;
        }

    #afwc_custom_referral_url_container {
        margin:0; padding-top:24px;

        p:nth-child(2),
        #afwc_custom_referral_url {
            @include flex-row;
            flex-wrap:wrap;
            align-items:center;
        }

        p:last-child {
            padding-top:12px;
        }
    }

        #afwc_custom_referral_url {

            @media screen and (min-width:769px) {
                padding-left:12px;
            }

            input {
                margin:-1px 6px 0; padding:0 12px;
                color:map-get($colors, _01-01);
                box-sizing:border-box;

                @media screen and (max-width:768px) {
                    margin:0 6px 0 0;
                }
            }
        }

        #afwc_resources_wrapper #afwc_generated_affiliate_link {

            @media screen and (max-width:768px) {
                display:block !important;
            }
        }

    #afwc_contact_admin_container {
        margin-top:0; padding-top:24px;

        a {
            @include animate;
            color:map-get($colors, _01-01);
            text-decoration:underline;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _04-01);
                    text-decoration:none;
                }
            }
        }
    }


/*
 * Campaigns
 */

#afw-campaigns {
    padding-top:48px;

    @media screen and (max-width:768px) {
        padding-top:24px;
    }
}
