/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.woocommerce-pagination {
    @include flex-column;
    align-items:center;
    margin-top:48px;
    border-top:1px solid map-get($colors, _01-04); border-bottom:1px solid map-get($colors, _01-04);

    .page-numbers {
        @include flex-row;
        list-style:none;

        .page-numbers {
            @include animate;
            display:block;
            padding:12px;
            color:map-get($colors, _01-01);
            font-weight:700;

            @media screen and (min-width:769px) {
                &:not(.current):not(.dots):hover,
                &:not(.current):not(.dots):focus {
                    color:map-get($colors, _04-01);
                }
            }

            &.current {
                color:map-get($colors, _04-01);
            }
        }
    }
}
