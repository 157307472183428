/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINK --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.fastlinks {

    &.gutter_24 {
        margin-top:-24px;
    }
    &.gutter_2 {
        margin-top:-2px;
    }

    & > .column {

        @media screen and (max-width:1189px) and (min-width:900px) {
            width:(4 / 12) * 100%;
        }
        @media screen and (max-width:899px) and (min-width:769px) {
            width:(6 / 12) * 100%;
        }
    }
}

.fastlink {
    @include flex-column;
    flex:1 1 auto;
    margin-top:24px;
}

.fastlink-image_wrapper {

    &:before {
        @include absolute(2);
        content:"";
        top:50%; left:0; right:0; bottom:0;
        background:linear-gradient(to bottom, rgba(map-get($colors, _01-01), 0) 0%, rgba(map-get($colors, _01-01), 1) 100%);
    }
}

.fastlink-content {
    @include flex-column;
    @include relative;
    flex:1 1 auto;
    margin-top:-48px; padding:32px;
    color:#FFF;
    text-align:center;

    &:before {
        @include absolute(-1);
        content:"";
        top:48px; left:0; right:0; bottom:0;
        background:map-get($colors, _01-01);
    }

    &.bg_transparent {
        &:before {
            background: transparent;
        }
    }
}
    .fastlink-text {
        padding-top:12px;
        line-height:map-get($line_heights, _small);
        font-size:map-get($font_sizes, _small);
    }
    .fastlink-link {
        margin-top:auto; padding-top:24px;
    }


/*
 * Styles
 */

.f-portrait,
.f-landscape {
    @include flex-row;
    @include relative;

    &:before {
        @include aspect-ratio('800 / 600'); // Aspect ratio: 3:4
    }

    .fastlink-image_wrapper {
        @include full-size(-1);

        &:before {
            display:none;
        }
    }
        .fastlink-image {
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
        }

    .fastlink-content {
        flex:1 1 auto;
        margin-top:0;

        &:before {
            top:0;
            opacity:.64;
        }
    }
        .fastlink-text {
            margin-top:auto;
            padding-top:24px;
        }
        .fastlink-link {
            padding-top:18px;
        }
}

// Portrait Style
.f-portrait:before {
    @include aspect-ratio('800 / 600'); // Aspect ratio: 3:4

    .fastlink-link {
        margin-top:0 !important;
    }
}

// Landscape Style
.f-landscape {
    margin-top:2px;

    &:before {
        @include aspect-ratio('450 / 800'); // Aspect ratio: 2:1
    }
}

.hero-slider + .pb-block_fastlinks {

    @media screen and (min-width:769px) {
        padding-top:2px;
    }
    @media screen and (max-width:768px) {
        padding:24px 0 48px;
    }
}
