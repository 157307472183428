/* ------------------------------------------------------------------------------------------------------------------------ */
/* CHECKOUT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.checkout.woocommerce-checkout {

    & > .col2-set {

        @media screen and (min-width:769px) {
            @include flex-row;
            justify-content:space-between;
        }

        & > .col-1,
        & > .col-2 {

            @media screen and (min-width:769px) {
                width:calc(50% - 24px);
            }
        }
        & > .col-2 {
            padding-top:63px;

            @media screen and (max-width:768px) {
                padding-top:24px;
            }
        }
    }

    .input-text {
        @include woo-input-text;
        height:auto;
        border:none !important;
    }
}


.woocommerce-form-login-toggle,
.woocommerce-form-coupon-toggle {
    padding-bottom:9px;
    border-bottom:1px solid map-get($colors, _01-04);

    a {
        @include animate;
        color:map-get($colors, _04-01);
        text-decoration:underline;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _04-01);
                text-decoration:none;
            }
        }
    }
}

.woocommerce-form-login-toggle + .woocommerce-form-login,
.woocommerce-form-coupon {
    @include flex-row;
    flex-wrap:wrap;

    p:first-child {
        width:100%;
    }

    .form-row {

        .input-text {
            @include woo-input-text;
            border:none !important;
        }

        .button {
            @include woo-button;
        }
    }
}


/*
 * Login
 */

.woocommerce-form-login-toggle + .woocommerce-form-login {

    p:first-child {
        padding:24px 0 48px;

        @media screen and (max-width:768px) {
            padding-bottom:24px;
        }
    }

    .form-row {
        order:4;
        width:100%;

        & > label:first-child:not(.woocommerce-form__label-for-checkbox) {
            @include relative(2);
            display:block;
            margin-bottom:-9px;
            padding:9px 18px 0;
            line-height:map-get($line_heights, _xsmall);
            color:map-get($colors, _01-01);
            font-size:map-get($font_sizes, _xsmall);
            background:#FFF;
        }

        & > label.woocommerce-form__label-for-checkbox {
            @include flex-row;
            align-items:center;

            & > span {
                cursor:pointer;
            }
        }

        .input-text {
            height:auto;
        }

        &.form-row-first,
        &.form-row-last {

            @media screen and (min-width:769px) {
                width:calc(50% - 24px);
            }
        }
        &.form-row-first {
            order:1;

            @media screen and (min-width:769px) {
                padding-right:48px;
            }
        }
        &.form-row-last {
            order:2;

            @media screen and (max-width:768px) {
                padding-top:6px;
            }
        }

        &:not(.form-row-first):not(.form-row-last) {
            @include flex-row;
            justify-content:space-between;
            align-items:center;

            @media screen and (min-width:769px) {
                padding-bottom:16px;
            }
            @media screen and (max-width:768px) {
                padding-top:12px;
            }
        }
    }

    .lost_password {
        order:3;
        width:100%;

        @media screen and (max-width:768px) {
            padding-top:6px;
        }

        a {

            @media screen and (min-width:769px) {
                margin-left:calc(50% + 24px);
            }
        }
    }

    & + .woocommerce-form-coupon-toggle {
        padding-top:24px;
    }
}


/*
 * Coupon
 */

.woocommerce-form-coupon-toggle {

    & + .woocommerce-error {
        margin-top:24px;
        list-style:none;
    }
}

.woocommerce-form-coupon {

    p:first-child {
        padding:24px 0 6px;

        @media screen and (max-width:768px) {
            padding-bottom:24px;
        }
    }

    .form-row {

        &.form-row-first {

            @media screen and (max-width:768px) {
                margin-bottom:6px;
                width:100%;
            }
        }

        &.form-row-last,
        &.form-row-last button {

            @media screen and (max-width:768px) {
                width:100%;
            }
        }

        .input-text {

            @media screen and (min-width:769px) {
                margin-right:6px;
                max-width:200px;
            }
        }
    }
}


/*
 * Billing
 */

.woocommerce-billing-fields {
    padding-top:48px;

    h3 {
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        padding-bottom:9px;
        font-weight:700;
        border-bottom:2px solid map-get($colors, _01-04);
    }
}
    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper,
    .woocommerce-address-fields__field-wrapper {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:space-between;

        .form-row {
            margin-top:6px;
            width:100%;

            &:first-child {
                margin-top:24px;
            }

            .woocommerce-input-wrapper {
                display:block;
            }

            & > label:first-child {
                @include relative(2);
                display:block;
                margin-bottom:-9px;
                padding:9px 18px 0;
                line-height:map-get($line_heights, _xsmall);
                color:map-get($colors, _01-01);
                font-size:map-get($font_sizes, _xsmall);
                background:#FFF;
            }

            &.address-field.crafty_billing,
            &.address-field.crafty_shipping {

                @media screen and (min-width:769px) {
                    width:calc(100% - 200px);
                }

                // Find Address Button
                & + .address-field.crafty_billing:not(.billing_cp_result_class),
                & + .address-field.crafty_shipping:not(.shipping_cp_result_class) {

                    @media screen and (min-width:769px) {
                        width:200px; height:62px;
                        background:#FFF;
                    }

                    label {
                        display:none;
                    }

                    .button {
                        @include woo-button;

                        @media screen and (min-width:769px) {
                            margin:6px;
                            line-height:1.3;
                        }
                        @media screen and (max-width:768px) {
                            padding:9px 18px;
                            width:100%;
                        }
                    }
                }

                // Enter Address Manually Button
                & > input + p:last-child {
                    @include animate;
                    display:inline-block;
                    margin:24px 18px 18px !important;
                    color:map-get($colors, _03-02);
                    font-weight:700;

                    @media screen and (min-width:769px) {
                        &:hover,
                        &:focus {
                            color:map-get($colors, _03-01);
                            text-decoration:underline;
                        }
                    }
                }

                // Address Selection
                & + .billing_cp_result_class,
                & + .shipping_cp_result_class {
                    margin-top:0;

                    & > span {
                        @include woo-select;
                        display:block;
                        float:none !important;
                        width:calc(100% + 200px) !important;
                        overflow:hidden;

                        & > select {
                            margin:6px 0 0 !important; padding:10px 18px !important;
                            background:#FFF !important;
                        }

                        &:after {
                            z-index:2;
                            top:22px;
                        }
                    }
                }
            }
        }
    }


/*
 * Account
 */

.woocommerce-account-fields {
    margin:24px 0; padding:24px 0;
    border-top:2px solid map-get($colors, _01-04); border-bottom:2px solid map-get($colors, _01-04);

    .form-row {

        &.create-account > label {
            @include flex-row;
            align-items:center;

            & > span {
                cursor:pointer;
            }
        }
    }

    .create-account .form-row {
        padding-top:6px;

        & > label:first-child {
            @include relative(2);
            display:block;
            margin-bottom:-9px;
            padding:9px 18px 0;
            line-height:map-get($line_heights, _xsmall);
            color:map-get($colors, _01-01);
            font-size:map-get($font_sizes, _xsmall);
            background:#FFF;
        }

        & > span:last-child {
            display:block;
        }
    }
}


/*
 * Shipping
 */

.woocommerce-shipping-fields {

    h3 {
        line-height:map-get($line_heights, _base);
        font-size:map-get($font_sizes, _base);
        font-weight:400;

        & > label {
            @include flex-row;
            align-items:center;

            & > span {
                cursor:pointer;
            }
        }
    }
}


/*
 * Additional
 */

.woocommerce-additional-fields {
    padding-top:48px;

    label {
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        padding-bottom:9px;
        font-weight:700;
        border-bottom:2px solid map-get($colors, _01-04);
    }

    textarea {
        margin-top:24px;
        height:200px !important;
    }
}


/*
 * Order Review
 */

#order_review_heading {
    @include heading(1.2, 1.4, 48px, 32px);
    padding-top:96px;
    text-align:center;

    @media screen and (max-width:1220px) {
        padding-top:48px;
    }
}

body:not(.woocommerce-cart) .shop_table,
.woocommerce-table--order-details,
.woocommerce-orders-table,
.afwc-table-header + table {
    margin-top:48px;
    width:100%;
    border-collapse:collapse; border-spacing:0;

    @media screen and (max-width:768px) {
        margin-top:8px;
    }

    tr {
        @media screen and (max-width:768px) {
            @include flex-row;
            margin-top:16px; padding-top:16px;
            border-top:1px solid map-get($colors, _01-04);

            &.shipping {
                flex-direction:column;
            }

            &.cart-subtotal th,
            &.order-total th {
                width:100%;
            }
        }
    }

    th {
        text-align:left;
    }
    th, td {

        @media screen and (min-width:769px) {
            padding:24px 0;
            border-bottom:1px solid map-get($colors, _01-04);
            vertical-align:top;

            &:not(.product-remove):not(:last-child) {
                padding-right:24px !important;
            }
        }
    }

    thead {

        @media screen and (max-width:768px) {
            display:none;
        }

        th {
            padding:0 0 9px;
            width:50%;
            line-height:map-get($line_heights, _small);
            font-size:24px;
            font-weight:700;
            font-family:map-get($font_families, _01);
            border-bottom:2px solid map-get($colors, _01-04);
        }
    }

    .product-name {
        font-weight:700;

        .variation {
            font-weight:400;
        }
    }
}


/*
 * Payment Methods
 */

#payment {

    a {
        @include animate;
        color:map-get($colors, _01-01);
        text-decoration:underline;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _04-01);
                text-decoration:none;
            }
        }
    }

    .woocommerce-terms-and-conditions-wrapper {
        padding-top:6px;
        line-height:map-get($line_heights, _small);
        font-size:map-get($font_sizes, _small);

        .woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
            @include flex-row;
            align-items:center;
            padding-top:6px;
            line-height:1.2;
        }
    }

    .automatewoo-optin {
        padding-top:6px;
        .woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
            @include flex-row;
            align-items:center;
            padding-top:6px;
            line-height:1.2;
        }
    }

    .button {
        @include woo-button;
        display:block;
        margin-top:48px;
        width:100%;
        text-align:center;
    }
}
    .payment_methods {
        padding-top:48px;
        list-style:none;

        /*
        .payment_box {
            padding-top:24px;
        }
        */

        .wc-payment-form {
            padding-top:24px;

            .form-row {

                &:not(:first-child) {
                    padding-top:6px;
                }

                & > label:first-child {
                    @include relative(2);
                    display:block;
                    margin-bottom:-9px;
                    padding:9px 18px 0;
                    line-height:map-get($line_heights, _xsmall);
                    color:map-get($colors, _01-01);
                    font-size:map-get($font_sizes, _xsmall);
                    background:#FFF;
                }
            }
        }
    }

        label[for="payment_method_opayopi"] {
            @include flex-row;
            align-items:center;

            img {
                padding-left:3px;

                &:first-child {
                    padding-left:12px;
                }
            }
        }


/*
 * Order Received
 */

.woocommerce-order-overview {
    margin-top:24px; padding:24px;
    list-style:none;
    border:1px solid map-get($colors, _01-04);
    box-sizing:border-box;
}

.woocommerce-order-details {
    padding-top:96px;

    h2 {
        @include heading(1.2, 1.4, 48px, 32px);
        text-align:center;
    }

    .product-name a {
        margin-right:6px;
    }

    .wc-item-meta {
        font-weight:400;
        list-style:none;

        li {
            @include flex-row;
            flex-wrap:wrap;

            strong {
                width:120px;
                font-weight:400;
            }
            p {
                width:calc(100% - 120px);
            }
        }
    }
}

.woocommerce-customer-details {
    padding-top:96px;

    .woocommerce-columns--addresses {
        padding-top:0;
    }

    h2 {
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        margin-bottom:18px; padding-bottom:9px;
        font-weight:700;
        border-bottom:2px solid map-get($colors, _01-04);
    }
}


/*
 * Notice
 */

/*
.woocommerce-NoticeGroup-checkout {
    margin-top:48px;
    padding:24px;
    background:#FFF;

    ul {
        list-style:none;
    }
}
*/
