/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * Archive
 */

.archive-wrapper {

    @media screen and (max-width:768px) {
        padding-left:24px !important; padding-right:24px !important;
    }

    .article-item {
        padding-top:48px;
    }

    .woocommerce-pagination {
        width:100%;
    }
}
    .archive-grid {
        margin-top:-48px;
    }

// Category Dropdown
.archive-category-dropdown_wrapper {
    margin:0 auto;
    width:100%; max-width:map-get($widths, _small);
}


/*
 * Slider
 */

.post-slider {
    margin:0 auto;
    width:100%; max-width:map-get($widths, _base);

    @media screen and (max-width:768px) {
        order:-1;
        margin-bottom:24px;
    }
}
    .post-slide {
        @include flex-column;
    }


/*
 * Card
 */

.article-item {
    @include flex-column;
    flex:1 1 auto;
}
    .article-thumb {
        @include flex-row;
        overflow:hidden;

        &:before {
            @include aspect-ratio('450 / 800'); // Aspect ratio: 16:9
        }
    }
        .article-image_wrapper {
            width:100%;
        }
    .article-text {
        @include flex-column;
        flex:1 1 auto;
        padding-right:24px;
    }
        .article-date {
            padding-top:24px;
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
        }
        .article-heading {
            padding-top:12px;

            &:first-child {
                padding-top:30px;
            }
        }
        .article-link_wrapper {
            margin-top:auto; padding-top:24px;
        }
