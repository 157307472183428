/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Filters
 */

.archive-products_filters {
    padding:12px 0;
    border-bottom:1px solid map-get($colors, _01-04);

    @media screen and (min-width:769px) {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:center;

        &.h-align_end {
            justify-content:flex-end;
        }
    }
    @media screen and (max-width:768px) {
        padding:12px 24px;
    }

    &:not(:first-child) {
        border-top:1px solid map-get($colors, _01-04);
    }

    .woocommerce-notices-wrapper {
        width:100%;
    }

    .woocommerce-result-count {
        flex:1 1 auto;

        @media screen and (min-width:769px) {
            padding-right:48px;
        }
    }

    .woocommerce-ordering {
        @include woo-select;
        width:100%;
        background:#FFF;

        @media screen and (min-width:769px) {
            max-width:260px;
        }
        @media screen and (max-width:768px) {
            margin-top:12px;
        }

        &:not(:last-child) {

            @media screen and (min-width:769px) {
                margin-right:6px;
            }
        }

        & + .field {
            margin-top:0;
            width:100%;
            border:none;

            @media screen and (min-width:769px) {
                max-width:260px;
            }
            @media screen and (max-width:768px) {
                margin-top:6px;
            }
        }
    }

    &.hide-default-sorting {
        .woocommerce-ordering {
            display: none;
        }
    }

    .field {
        margin-top:0;
    }
}
    .archive-products_filters-label {

        @media screen and (min-width:769px) {
            padding-right:12px;
        }
        @media screen and (max-width:768px) {
            padding-bottom:6px;
        }
    }
