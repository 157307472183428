/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLOCKQUOTE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.blockquote-wrapper {
    text-align:center;
}
    .blockquote {
        @include heading(1.3, 1.5, 32px, 24px);
    }
    .blockquote-author {
        padding-top:24px;

        @media screen and (max-width:768px) {
            padding-top:18px;
        }
    }
