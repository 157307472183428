/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: CASE STUDIES GRID -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_case-studies-grid {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }

    .archive-wrapper.archive-grid {

        @media screen and (max-width:768px) {
            padding-left:0 !important; padding-right:0 !important;
        }
    }
}
