/* ------------------------------------------------------------------------------------------------------------------------ */
/* TESTIMONIAL ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.testimonials-slider {
    margin:0 auto;
    width:100%; max-width:map-get($widths, _medium_small);

    @media screen and (max-width:768px) {
        order:-1;
        margin-bottom:48px;
    }
}
    .testimonial-slide.swiper-slide {
        height:auto;

        .blockquote-wrapper {
            @include flex-column;
            justify-content:center;
            height:100%;
        }
    }
