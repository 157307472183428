/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.typography,
.woocommerce-product-details__short-description,
.woocommerce-Tabs-panel--description {
    color:map-get($colors, _01-01);


    /*
     * Headings
     */

    h1, h2, h3, h4, h5, h6 {
        color:map-get($colors, _01-01);
        font-weight:700;
    }
    h5 {
        font-weight:400;
    }


    /*
     * Strong
     */

    strong {
        font-weight:700;
    }


    /*
     * Lists
     */

    ul:not(.archive-item_categories-list) {
        list-style:none;

        li {
            @include relative;
            padding-left:32px;

            @media screen and (max-width:768px) {
                padding-left:24px;
                text-align:left;
            }

            &:not(:last-child) {
                padding-bottom:1em;
            }

            &:before {
                @include absolute;
                content:"";
                top:0; left:0;
                margin-top:9px;
                margin-left: 10px;
                width:10px; height:10px;
                background:map-get($colors, _01-01);
                border-radius:100%;

                @media screen and (max-width:768px) {
                    margin-top:8px;
                    width:9px; height:9px;
                }
            }
        }
    }

    ol {
        list-style:none;
        counter-reset:ol-counter;

        li {
            counter-increment:ol-counter;

            @media screen and (max-width:768px) {
                text-align:left;
            }

            &:not(:last-child) {
                padding-bottom:1em;
            }

            &:before {
                display: inline-block;
                content:counter(ol-counter);
                flex-shrink:0;
                padding-right:24px;
                color:map-get($colors, _01-01);
                font-weight:500;
            }
        }
    }


    /*
     * Anchors
     */

    a:not(.btn):not(.link) {
        @include animate;
        color:map-get($colors, _03-02);
        font-weight:700;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _03-01);
                text-decoration:underline;
            }
        }
    }


    /*
     * Spacing (Normal)
     */

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, .btns-wrapper {

        &:not(:first-child) {
            padding-top:1.5em;

            @media screen and (max-width:768px) {
                padding-top:1.25em;
            }
        }
    }


    /*
     * Spacing (Shorter)
     */

    &.half-spacing {

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, .btns-wrapper {

            &:not(:first-child) {

                @media screen and (min-width:769px) {
                    padding-top:1.25em;
                }
            }
        }
    }

    /*
     * Spacing (Shortest)
     */

    &.quarter-spacing {

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, .btns-wrapper {

            &:not(:first-child) {
                padding-top:.5em;
            }
        }
    }


    /*
     * Light Typography
     */

    &.typo-light {
        color:#FFF;

        h1, h2, h3, h4, h5, h6, strong {
            color:#FFF;
        }

        a:not(.btn):not(.link) {
            color:#FFF;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _01-01);
                }
            }
        }

        a.btn {
            color:#FFF;
        }
    }
}
