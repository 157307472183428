/* ------------------------------------------------------------------------------------------------------------------------ */
/* PARTNER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Map
 */

.partner-map {
    @include relative;
    @include flex-row;

    @media screen and (max-width:768px) {
        margin-top:48px;
    }

    &:before {
        @include aspect-ratio('600 / 800'); // Aspect ratio: 1:1
    }

    iframe {
        @include full-size(-1);
        display:block;
        width:100%; height:100%;
        border:none;
    }
}


/*
 * Logos
 */

.partner-logos {
    justify-content:center;
    margin-top:-24px;

    @media screen and (max-width:1024px) and (min-width:769px) {
        & > .column {
            width:(4 / 12) * 100%;
        }
    }
    @media screen and (max-width:768px) {
        margin-top:-12px;
    }
}
    .partner-logo {
        @include flex-row;
        @include relative;
        margin-top:24px; padding:24px;
        border:1px solid map-get($colors, _01-04);

        @media screen and (max-width:768px) {
            margin-top:12px;
        }

        &:before {
            @include aspect-ratio('450 / 800'); // Aspect ratio: 16:9
        }
    }
        .partner-logo-image_wrapper {
            @include full-size(-1);
            top:24px; left:24px; right:24px; bottom:24px;
        }
            .partner-logo-image {
                height:100% !important;
                object-fit:contain; font-family:'object-fit:contain;';
            }
