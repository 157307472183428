/* ------------------------------------------------------------------------------------------------------------------------ */
/* GALLERY ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Gallery (Slider)
 */

.gallery-slider {

    .swiper-wrapper {
        align-items:center;
    }
}

    .gallery-slide {
        overflow:hidden;

        @media screen and (min-width:769px) {
            width:calc(100% - 96px); max-width:calc(#{map-get($widths, _large)} - 96px);
        }

        &:not(.swiper-slide-active) .gallery-image {
            transform:scale(.8);
        }

        &.swiper-slide-prev .gallery-image {
            transform-origin:center right;
        }
        &.swiper-slide-next .gallery-image {
            transform-origin:center left;
        }
    }
        .gallery-image_wrapper {
            padding:0 122px;

            @media screen and (max-width:1024px) and (min-width:769px) {
                padding:0 74px;
            }
            @media screen and (max-width:768px) {
                padding:0 24px;
            }
        }
            .gallery-image {
                @include animate;
            }

.gallery-slider_controls {

    @media screen and (min-width:769px) {
        @include absolute;
        top:50%; left:50%;
        width:100%; max-width:map-get($widths, _large); height:0;
        transform:translateX(-50%);
    }
    @media screen and (max-width:768px) {
        @include flex-row;
        justify-content:center;
        padding-top:24px;
    }
}
    .gallery-slider_prev.slider-prev,
    .gallery-slider_next.slider-next {

        @media screen and (min-width:769px) {
            @include absolute(2);
            top:50%;
            transform:translateY(-50%);
        }
    }
    .gallery-slider_prev {

        @media screen and (min-width:1025px) {
            left:96px;
        }
        @media screen and (max-width:1024px) and (min-width:769px) {
            left:72px;
        }
    }
    .gallery-slider_next {

        @media screen and (min-width:1025px) {
            right:96px;
        }
        @media screen and (max-width:1024px) and (min-width:769px) {
            right:72px;
        }
    }


/*
 * Gallery (Modal)
 */

.gallery-modal_trigger {
    display:block;
    width:100%; height:auto;
}

.gallery-modal_wrapper {

    @media screen and (max-width:768px) {
        @include flex-column;
        justify-content:center;
        padding:0 12px;
    }

    & + .pb-block {
        margin-top:-96px !important;

        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-top:-48px !important;
        }
        @media screen and (max-width:768px) {
            margin-top:-48px !important;
        }
    }
}
    .gallery-modal_content {
        height:calc(100vh - 144px);

        @media screen and (max-width:768px) {
            width:calc(100vw - 24px);
        }
    }
        .gallery-modal {
            height:calc(100vh - 240px);

            @media screen and (max-width:768px) {
                height:calc(100vh - 144px);
            }
        }
            .gallery-modal_item,
            .gallery-modal_image-wrapper {
                @include flex-column;
                flex:1 1 auto;
                height:100%;
            }
                .gallery-modal_image {
                    @include full-size;
                    width:100%; height:100% !important;
                    object-fit:contain; font-family:'object-fit:contain;';
                }
                .lazyload-caption {
                    text-align: center;
                }

                .gallery-modal_item-with-products {
                    @include flex-column;
                    .gallery-modal_image-wrapper {
                        flex-grow: 1;
                        height: calc(100% - 160px);
                    }
                }
                .gallery-modal_products {
                    @include flex-row;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;
                    color:map-get($colors, _01-01);
                    padding-bottom: 24px;
                }
                .gallery-modal_extra-text {
                    @include flex-row;
                    align-items: center;
                    justify-content: center;
                    color:map-get($colors, _01-01);

                    a {
                        text-decoration: underline;
                    }
                }

.gallery-modal_controls {
    @include flex-row;
    position:fixed;
    z-index:2;

    @media screen and (min-width:769px) {
        top:48px; right:48px;
    }
    @media screen and (max-width:768px) {
        left:50%; bottom:24px;
        transform:translateX(-50%);
    }
}
    .gallery-modal_btn {
        @include animate;
        @include flex-column;
        justify-content:center;
        align-items:center;
        width:32px; height:32px;

        @media screen and (max-width:768px) {
            margin:0 6px;
            width:24px; height:24px;
        }

        &:not(:last-child) {

            @media screen and (min-width:769px) {
                margin-right:24px;
            }
        }

        svg {
            @include animate;
            display:block;
            width:32px; height:32px;
            fill:map-get($colors, _02-01);

            @media screen and (max-width:768px) {
                width:24px; height:24px;
            }
        }

        @media screen and (min-width:769px) {
            &:not(.swiper-button-disabled):hover svg,
            &:not(.swiper-button-disabled):focus svg {
                fill:map-get($colors, _04-01);
            }

            &.gallery-modal_btn-close:not(.swiper-button-disabled):hover svg,
            &.gallery-modal_btn-close:not(.swiper-button-disabled):focus svg {
                transform:rotate(180deg);
            }
        }

        &.swiper-button-disabled {
            opacity:.24;
            cursor:default;
        }
    }
    .gallery-modal_btn-prev svg {

        @media screen and (max-width:768px) {
            transform:rotate(180deg);
        }
    }
    .gallery-modal_btn-next svg {

        @media screen and (min-width:769px) {
            transform:rotate(180deg);
        }
    }


/*
 * Gallery (Plus)
 */

.gallery-modal_plus {
    @include absolute;
    top:0; right:0;
    width:24px; height:24px;
    // border:2px solid #FFF;
    // border-radius:100%;

    /*
    &:before,
    &:after {
        @include absolute;
        content:"";
        top:7px; left:15px;
        width:2px; height:18px;
        background:#FFF;
    }
    &:after {
        transform:rotate(90deg);
    }
    */

    svg {
        display:block;
        width:24px; height:24px;
        fill:#FFF;
    }
}

.dark-form .gallery-modal_plus,
.product_tag-whites-shades .gallery-modal_plus {
    // border-color:map-get($colors, _01-01);

    /*
    &:before,
    &:after {
        background:map-get($colors, _01-01);
    }
    */

    svg {
        fill:map-get($colors, _01-01);
    }
}
