.three-quarter-media {
    width: 75%;
    margin: 0 auto;
    @media screen and (max-width:768px) {
        width: 100%;
    }
}
.half-width-media {
    width: 50%;
    margin: 0 auto;
    @media screen and (max-width:768px) {
        width: 100%;
    }
}