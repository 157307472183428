/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOUR SCHEME ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.colour-schemes-slider {
    margin:0 auto;
    width:100%;

    @media screen and (max-width:768px) {
        order:-1;
        margin-bottom:48px;
    }
}

.colour-schemes {
    // justify-content:center;
    // margin-top:-24px;
}
    .colour-scheme {
        @include flex-row;
        // flex-direction:row !important;
        flex-wrap:wrap !important;
        // padding-top:24px;
    }
        .colour-scheme_primary,
        .colour-scheme_secondary {
            @include flex-row;

            picture,
            img {
                width:100%;
            }
        }
        .colour-scheme_primary {
            width:100%;

            &:before {
                @include aspect-ratio('450 / 800'); // Aspect ratio: 16:9
            }
        }
        .colour-scheme_secondary {
            @include relative;
            flex-wrap:wrap;
            width:50%;

            &:before {
                @include aspect-ratio('800 / 800'); // Aspect ratio: 1:1
            }
        }

.colour-scheme_title {
    display:block;
    padding:12px 12px 0;
    width:100%;
    min-height: 50px;
    line-height:1.2;
    font-size:map-get($font_sizes, _small);
    text-align:center;
}
