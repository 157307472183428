/* ------------------------------------------------------------------------------------------------------------------------ */
/* SEARCH ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Header Search
 */

.header-search_trigger-wrapper {
}
    .header-search_trigger {
        @include flex-row;
        align-items:center;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus,
            &:active {

                .header-search_trigger-icon:before {
                    opacity:1;
                }
            }
        }
    }
        .header-search_trigger-label {
            padding-right:8px;
        }
        .header-search_trigger-icon {

            &:before {
                @include animate;
                @include absolute(-1);
                content:"";
                top:50%; left:50%;
                width:48px; height:48px;
                background:rgba(map-get($colors, _01-05), .48);
                border-radius:100%;
                transform:translate(-50%, -50%);
                opacity:0;

                @media screen and (max-width:768px) {
                    width:24px; height:24px;
                }
            }
        }

.header-search.js-panel {
    @include flex-row;
    padding:0;

    @media screen and (max-width:768px) {
        position:static;
        opacity:1;
        visibility:visible;
        width:100%; max-width:none;
        background:none;
        box-shadow:none;
        transform:none;
    }

    .input-text {
        flex:1 1 auto;
    }
}
    .header-search_input {
        font-weight:400 !important;

        @media screen and (max-width:768px) {
            padding:18px 24px !important;
        }
    }
    .header-search_btn {
        @include flex-column;
        justify-content:center;
        align-items:center;
        width:43px; height:43px;

        @media screen and (max-width:768px) {
            width:61px; height:61px;
        }

        span,
        svg {
            width:15px; height:15px;

            @media screen and (max-width:768px) {
                width:18px; height:18px;
            }
        }

        svg {
            @include animate;
            fill:map-get($colors, _01-01);
        }

        @media screen and (min-width:769px) {
            &:not(:disabled):hover,
            &:not(:disabled):focus {

                svg {
                    fill:map-get($colors, _04-01);
                }
            }
        }
    }

.page-template-search .colour-scheme-builder_trigger {
    display:none !important;
}
