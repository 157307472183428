/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: GALLERY ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_gallery {
    padding-left:0; padding-right:0;

    @media screen and (min-width:769px) {
        margin-left:calc(((calc(100vw - 17px) - 100%) / 2) * -1);
        width:calc(100vw - 17px);
    }

    .pb-block_content {
        // @include flex-row;
        // align-items:center;
    }
}
