.styled-table {
    width: 100%;
    overflow-y: auto;
    margin-top: 2rem;
    border: solid 1px map_get($colors, _01-04);

    table {
        border-collapse: collapse;
        font-size: 1rem;
    }

    th {
        padding: 0.25rem 0.5rem;
        text-align: left;
        border: solid 1px map_get($colors, _01-04);
    }

    td {
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
        border: solid 1px map_get($colors, _01-04);
    }

    .table-divider {
        border-bottom: solid 2px map_get($colors, _01-04);
        padding: 0.25rem;
    }

    @media screen and (min-width:900px) {
        overflow-y: unset;
    }
}

