/* ------------------------------------------------------------------------------------------------------------------------ */
/* NOTICE ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.woocommerce-notices-wrapper {
    margin-bottom:48px;

    &:empty {
        display:none;
    }
}

.woocommerce-NoticeGroup {
    padding-top:48px;
}

    .woocommerce-message,
    .woocommerce-notice,
    .woocommerce-notices-wrapper .woocommerce-info:not(.cart-empty),
    ul.woocommerce-error li {
        @include flex-row;
        padding:16px 24px;
        background:#FFF;
        list-style:none;

        &:not(:first-child) {
            margin-top:6px;
        }

        a {
            @include animate;
            order:2;
            flex-shrink:0;
            margin-left:auto;
            color:map-get($colors, _03-02);
            font-weight:700;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _03-01);
                    text-decoration:underline;
                }
            }
        }

        strong {
            padding-right:4px;
        }
    }

// .woocommerce-message,
// .woocommerce-notice {
//     margin-bottom:48px;

//     &:not(:last-child) {
//         margin-bottom:6px;
//     }
// }

// ul.woocommerce-error {
//     margin-top:48px;
// }

body.single-product {

    .woocommerce-notices-wrapper {
        margin:6px 0 0;
    }
}

// body.woocommerce-cart {

//     .woocommerce-message:not(:first-child),
//     ul.woocommerce-error li:not(:first-child) {
//         margin-top:6px;
//     }

//     /*
//     .woocommerce-message:last-child,
//     ul.woocommerce-error li:last-child {
//         margin-bottom:48px;
//     }
//     */
// }

// body.woocommerce-checkout,
// body.woocommerce-account {

//     ul.woocommerce-error li:not(:first-child) {
//         margin-top:6px;
//     }

//     /*
//     ul.woocommerce-error li:last-child {
//         margin-bottom:48px;
//     }
//     */
// }
