/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOUR SCHEME BUILDER -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin color-palette-2-1 {
    width:100%;

    &:before {
        @include aspect-ratio('400 / 800'); // Aspect ratio: 2:1
    }
}
@mixin color-palette-1-1 {
    width:50%;

    // margin-top:2px;
    // width:calc(50% - 1px);

    &:before {
        @include aspect-ratio('800 / 800'); // Aspect ratio: 1:1
    }
}

/*
@mixin color-palette-1-1-expanded {
    margin-top:0;
    width:50%;

    &:before {
        @include aspect-ratio('800 / 800'); // Aspect ratio: 1:1
    }
}
*/

/*
 * Modal
 */

.colour-scheme-builder_modal {

    &.is-expanded {

        .js-modal_content {
            @include relative;
            top:auto; left:auto;
            margin:0 auto;
            transform:none;
        }

        .colour-scheme-builder_palette {
            position:fixed;
            top:0; left:0; right:0; bottom:0;
            z-index:10;
            // padding:2px;
            background:#FFF;

            .modal_btn-close {
                display:block;
            }

            /*
            &.rotate-0 {

                .colour-scheme-builder_palette_color.color-b,
                .colour-scheme-builder_palette_color.color-c {
                    @include color-palette-1-1-expanded;
                }
            }
            &.rotate-1 {

                .colour-scheme-builder_palette_color.color-a,
                .colour-scheme-builder_palette_color.color-c {
                    @include color-palette-1-1-expanded;
                }
            }
            &.rotate-2 {

                .colour-scheme-builder_palette_color.color-a,
                .colour-scheme-builder_palette_color.color-b {
                    @include color-palette-1-1-expanded;
                }
            }
            */
        }
            .colour-scheme-builder_palette_color {

                &.color-a:before,
                &.color-b:before,
                &.color-c:before,
                .color-label {
                    display:none !important;
                }
            }

        .modal_btn-close {
            display:none;
        }
    }
}
    .colour-scheme-builder_wrapper {

        &.has_ajax-loader.ajax-loader_full:before,
        &.has_ajax-loader.ajax-loader_full:after {
            z-index:4;
        }
        &.has_ajax-loader.ajax-loader_full:after {

            @media screen and (max-width:768px) {
                position:fixed;
            }
        }
    }

        // Intro
        .colour-scheme-builder_intro {
            @include flex-row;

            @media screen and (min-width:769px) {
                justify-content:space-between;
                align-items:center;
            }
            @media screen and (max-width:768px) {
                flex-direction:column;
            }

            h2 {

                @media screen and (min-width:769px) {
                    padding-right:48px;
                }
                @media screen and (max-width:768px) {
                    padding-top:12px;
                }
            }

            .js-panel-trigger {
                z-index:3;

                @media screen and (max-width:768px) {
                    align-self:flex-start;
                    order:-1;

                    .btn {
                        line-height:1.2;
                    }
                }
            }
                .js-panel {
                    width:auto; min-width:0; max-width:none;

                    @media screen and (max-width:768px) {
                        left:0; right:auto;
                    }
                }
        }

        // Content
        .colour-scheme-builder {
            padding-top:48px;

            @media screen and (max-width:768px) {
                padding-top:24px;
            }
        }


/*
 * Trigger
 */

.colour-scheme-builder_trigger {
    position:fixed;
    top:18px; right:0;
    z-index:99;
    min-width:0 !important;

    @media screen and (min-width:769px) {
        flex-direction:column !important;
        padding:24px 12px;
    }

    @media screen and (max-width:768px) {
        justify-content:center;
        align-items:center;
        top:auto; right:12px; bottom:12px;
        padding:0;
        width:48px; height:48px;
        border-radius:100%;
    }

    .btn-icon {

        @media screen and (min-width:769px) {
            margin:0 0 9px 0 !important;
        }
        @media screen and (max-width:768px) {
            margin-right:0 !important;
        }

        svg {
            width:32px; height:32px;

            @media screen and (max-width:768px) {
                width:28px; height:28px;
            }
        }
    }

    .btn-label {
        @include animate;

        @media screen and (min-width:769px) {
            writing-mode: vertical-rl;
            text-orientation: mixed;
        }
        @media screen and (max-width:768px) {
            @include absolute;
            background:rgba(#000, .72);
            font-size:12px;
            text-transform:none;
            font-weight:400;
            min-width:140px;
            top:50%;
            transform:translateY(-50%);
            right:54px;
            padding:3px;
            border-radius:4px;

            &:before {
                @include absolute;
                top:50%; right:-6px;
                content:"";
                margin-top:-2px;
                width:0; height:0;
                border-left:4px solid transparent; border-right:4px solid transparent; border-top:4px solid rgba(#000, .72);
                transform:rotate(-90deg);
            }
        }
    }

    &.is-cookie-set {

        .btn-label {

            @media screen and (max-width:768px) {
                opacity:0 !important;
                visibility:hidden !important;
            }
        }
    }
}


/*
 * Palette
 */

.colour-scheme-builder_palette {
    @include flex-row;
    flex-wrap:wrap;
    justify-content:space-between;

    .modal_btn-close {
        display:none;

        &.light svg {
            fill:#FFF;
        }
    }

    &.rotate-0 {

        .colour-scheme-builder_palette_color.color-a {
            @include color-palette-2-1;
        }
        .colour-scheme-builder_palette_color.color-b,
        .colour-scheme-builder_palette_color.color-c {
            @include color-palette-1-1;
        }
    }
    &.rotate-1 {

        .colour-scheme-builder_palette_color.color-a {
            order:3;
        }
        .colour-scheme-builder_palette_color.color-a,
        .colour-scheme-builder_palette_color.color-c {
            @include color-palette-1-1;
        }
        .colour-scheme-builder_palette_color.color-b {
            @include color-palette-2-1;
            order:1;
        }
        .colour-scheme-builder_palette_color.color-c {
            order:2;
        }
    }
    &.rotate-2 {

        .colour-scheme-builder_palette_color.color-a {
            order:2;
        }
        .colour-scheme-builder_palette_color.color-a,
        .colour-scheme-builder_palette_color.color-b {
            @include color-palette-1-1;
        }
        .colour-scheme-builder_palette_color.color-c {
            @include color-palette-2-1;
            order:1;
        }
        .colour-scheme-builder_palette_color.color-b {
            order:3;
        }
    }
}
    .colour-scheme-builder_palette_color {
        @include relative;
        box-sizing:border-box;

        &.no-color {
            // border:2px dashed map-get($colors, _01-04);

            &:after {
                @include absolute;
                content:"";
                top:1px; left:1px; right:1px; bottom:1px;
                border:2px dashed map-get($colors, _01-04);
            }

            .color-label {
                // top:16px; left:16px;
            }
        }

        &.light .color-label {
            color:#FFF;
            border-color:#FFF;
        }

        .color-label {
            @include flex-column;
            @include absolute;
            justify-content:center;
            align-items:center;
            top:18px; left:18px;
            width:36px; height:36px;
            line-height:1;
            font-size:map-get($font_sizes, _xsmall);
            font-weight:700;
            text-transform:uppercase;
            border:2px solid map-get($colors, _01-01);
            border-radius:100%;
            box-sizing:border-box;
        }
    }

.btn-colour-scheme-builder_rotate {
    margin:24px auto 0;
}


/*
 * Accordion
 */
.colour-scheme-builder_accordion {
    list-style:none;

    @media screen and (max-width:768px) {
        padding-top:24px;
    }
}

    .colour-scheme-builder_accordion-item {
        padding:18px 24px;
        border:1px solid map-get($colors, _01-04);
        box-sizing:border-box;

        @media screen and (max-width:768px) {
            padding:12px;
        }

        &:not(:first-child) {
            margin-top:12px;

            @media screen and (max-width:768px) {
                margin-top:6px;
            }
        }

        &.open .colour-scheme-builder_accordion-item_chevron svg,
        &.open .colour-scheme-builder_accordion-item_chevron svg {
            transform:rotate(-90deg);
        }

        // Color Picker Filter
        .color-picker_wrapper {
            margin-top:18px; padding:24px 0 6px;
            border-top:1px solid map-get($colors, _01-04);

            @media screen and (max-width:768px) {
                margin-top:12px; padding:12px 0 0;
            }
        }
            .color-picker_filter {
                @include flex-row;

                @media screen and (min-width:769px) {
                    justify-content:space-between;
                    align-items:center;
                }
                @media screen and (max-width:768px) {
                    flex-direction:column;
                }

                strong {
                    line-height:map-get($line_heights, _small);
                    font-size:map-get($font_sizes, _small);
                }

                .field {
                    margin-top:0;

                    @media screen and (max-width:768px) {
                        margin-top:3px;
                    }
                }
                    .field-select select {
                        padding:6px 12px;
                        min-width:210px;
                    }
                    .field-select .chevron {
                        right:12px;
                    }
            }

        // Color Picker Carousel
        .color-picker_carousel {
            @include flex-row;
            align-items:center;
            padding-top:24px;

            @media screen and (max-width:768px) {
                padding-top:12px;
            }
        }
            .color-picker_slider {
                width:100%;
            }
                .color-picker_item {
                    @include relative;
                    background-color:#F5F5F5;
                    cursor:pointer;

                    &:before {
                        @include aspect-ratio('800 / 800'); // Aspect ratio: 1:1
                    }
                    &:after {
                        @include animate;
                        @include full-size(2);
                        content:"";
                        border:3px solid map-get($colors, _01-01);
                        opacity:0;
                    }

                    &.is-selected:after {
                        opacity:1;
                    }

                    img {
                        @include full-size;
                        width:100%; height:100%;
                        object-fit:cover; font-family:'object-fit:cover;';
                    }
                }
            .color-picker-slider_prev,
            .color-picker-slider_prev svg,
            .color-picker-slider_next,
            .color-picker-slider_next svg {
                width:18px; height:18px;
            }
            .color-picker-slider_prev {
                margin-right:18px;

                @media screen and (max-width:768px) {
                    margin-right:6px;
                }

                svg {
                    transform:rotate(180deg);
                }
            }
            .color-picker-slider_next {
                margin-left:18px;

                @media screen and (max-width:768px) {
                    margin-left:6px;
                }

                svg {
                    transform:rotate(0deg);

                    @media screen and (max-width:768px) {
                        margin-left:0;
                    }
                }
            }
    }

        .colour-scheme-builder_accordion-item_heading {
            @include flex-row;
            align-items:center;
            cursor:pointer;

            .color-label {
                @include flex-column;
                justify-content:center;
                align-items:center;
                flex-shrink:0;
                width:36px; height:36px;
                line-height:1;
                font-size:map-get($font_sizes, _xsmall);
                font-weight:700;
                text-transform:uppercase;
                border-radius:100%;
                box-sizing:border-box;

                &.no-color {
                    border:2px dashed map-get($colors, _01-04);
                }

                &.light {
                    color:#FFF;
                }
            }
            .color-heading {
                flex:1 1 auto;
                padding:0 24px;
                font-weight:700;
            }
        }
            .colour-scheme-builder_accordion-item_chevron {
                flex-shrink:0;
                width:18px; height:18px;

                svg {
                    @include animate;
                    display:block;
                    width:18px; height:18px;
                    fill:map-get($colors, _01-01);
                    transform:rotate(90deg);
                }

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover svg,
                    &:not(:disabled):focus svg {
                        fill:map-get($colors, _04-01);
                    }
                }
            }


/*
 * CTAs
 */

.colour-scheme-builder_ctas {
    padding-top:48px;

    @media screen and (max-width:768px) {
        padding-top:24px;

        & > .column:not(:first-child) {
            padding-top:6px;
        }
    }
}


/*
 * Disclaimer
 */

.colour-scheme-builder_disclaimer {
    padding-top:24px;
    line-height:map-get($line_heights, _small);
    font-size:map-get($font_sizes, _small);
}


/*
 * Add to color scheme
 */

.colour-scheme-builder_rhc {
    @include relative(2);
    align-self:flex-start;
}

.colour-scheme-builder_add-to_dropdown-wrapper {
    @include animate;
    opacity:0;
    visibility:hidden;

    @media screen and (min-width:769px) {
        @include flex-column;
        @include full-size(2);
        justify-content:center;
        align-items:center;
        left:24px; right:24px;
        padding:24px;
        background:rgba(#FFF, .88);
        border:1px solid map-get($colors, _01-04);
        box-sizing:border-box;
    }
    @media screen and (max-width:768px) {
        position:fixed;
        z-index:3;
        left:12px; right:12px; bottom:12px;
    }

    &.show {
        opacity:1;
        visibility:visible;
    }
}
    .colour-scheme-builder_add-to_dropdown-container {
        padding:24px;
        width:100%;
        background:map-get($colors, _01-05);
        box-shadow:0 3px 48px 0 rgba(#000, .12);

        @media screen and (min-width:769px) {
            max-width:200px;
        }
        @media screen and (max-width:768px) {
            box-sizing:border-box;
        }

        .field {
            margin-top:6px !important;
        }
            .field-select select {
                padding:6px 12px;
            }
            .field-select .chevron {
                right:12px;
            }
    }
        .colour-scheme-builder_add-to_dropdown-heading {
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
        }
