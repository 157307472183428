/* ------------------------------------------------------------------------------------------------------------------------ */
/* REVIEW ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.reviews {
    justify-content:center;
    margin-top:-24px;

    @media screen and (max-width:768px) {
        margin-top:-12px;
    }

    & > .column {

        @media screen and (max-width:1100px) and (min-width:769px) {
            width:(6 / 12) * 100%;
        }
    }
}
    .review {
        @include flex-row;
        flex:1 1 auto;
        margin-top:24px;
        padding:32px;
        background:#FFF;

        @media screen and (max-width:768px) {
            margin-top:12px;
            padding:24px;
        }
    }
        .review-content {
            flex:1 1 auto;
        }
            .review-heading {
                font-weight:700;

                @media screen and (min-width:769px) {
                    padding-top:10px;
                }
            }
            .review-text {
                padding-top:12px;
                line-height:map-get($line_heights, _xsmall);
            }


/*
 * Badge
 */

.review-badge {
    @include flex-column;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    margin-right:16px;
    width:48px; height:48px;
    color:#FFF;
    font-weight:700;
    text-transform:uppercase;
    background:map-get($colors, _06-01);
    border-radius:100%;
}


/*
 * Rating
 */

.review-rating {
    @include flex-row;
    align-items:center;
    padding-top:9px;
}
    .review-star {
        width:16px; height:16px;

        &:not(:last-child) {
            padding-right:4px;
        }

        svg {
            display:block;
            width:16px; height:16px;
            fill:map-get($colors, _05-02);
        }
    }


/*
 * Date
 */

.review-date {
    padding-left:12px;
    line-height:map-get($line_heights, _xsmall);
    color:map-get($colors, _01-02);
    font-size:map-get($font_sizes, _xsmall);
}
