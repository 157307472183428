/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.nav-top,
.nav-primary,
.nav-top_list, .nav-top_item,
.nav-primary_list, .nav-primary_item {

    @media screen and (min-width:769px) {
        @include flex-row;
    }
    @media screen and (max-width:1023px) and (min-width:769px) {
        flex-wrap:wrap;
    }
}
.nav-top {
    justify-content:flex-end;
}
    .nav-top_list, .nav-top_sublist,
    .nav-primary_list, .nav-primary_sublist {
        list-style:none;
    }
    .nav-primary_list {

        @media screen and (min-width:903px) {
            justify-content:space-between;
        }
    }
        .nav-top_subitem,
        .nav-primary_subitem {
            @include flex-row;
            flex-wrap:wrap;
        }


.has-sublist {

    @media screen and (min-width:769px) {
        &:after {
            @include absolute;
            content:"";
        }

        &:hover > .nav-top_sublist, &:focus > .nav-top_sublist,
        &:hover > .nav-primary_sublist, &:focus > .nav-primary_sublist {
            opacity:1;
            visibility:visible;
        }
    }
}


/*
 * Item
 */

.nav-top_item,
.nav-primary_item {
    @include relative;
    align-items:center;
    flex-shrink:0;

    &:not(:last-child) {

        @media screen and (min-width:1321px) {
            margin-right:32px;
        }
        @media screen and (max-width:1320px) and (min-width:769px) {
            margin-right:24px;
        }
    }
    &:not(:first-child) {

        @media screen and (max-width:768px) {
            margin-top:16px;
        }
    }

    &.has-sublist {

        @media screen and (min-width:769px) {
            &:after {
                left:0; right:0; bottom:-16px;
                height:16px;
            }

            &:hover > .nav-top_sublist, &:focus > .nav-top_sublist,
            &:hover > .nav-primary_sublist, &:focus > .nav-primary_sublist {
                transform:translateY(0);
            }
        }
    }

    & > .nav-top_sublist,
    & > .nav-primary_sublist {

        @media screen and (min-width:769px) {
            top:35px; left:-19px;
            transform:translateY(6px);
        }
    }
}
.nav-top_item {

    & > span.nav-top_icon svg {

        @media screen and (max-width:768px) {
            transform:rotate(90deg);
        }
    }
}
.nav-primary_item {

    & > .nav-primary_icon svg {
        transform:rotate(90deg);
    }
}

.nav-top_item-account {

    @media screen and (max-width:768px) {
        @include flex-row;
    }
}


/*
 * Link
 */

.nav-top_link, .nav-top_sublink,
.nav-primary_link, .nav-primary_sublink {
    @include animate;
    @include relative(2);
    line-height:map-get($line_heights, _xsmall);
    color:map-get($colors, _01-01);
    font-size:map-get($font_sizes, _xsmall);
    text-transform:uppercase;

    &:not(:last-child) {
        margin-right:9px;
    }

    @media screen and (min-width:769px) {

        &:not(:disabled):hover,
        &:not(:disabled):focus {
            color:map-get($colors, _04-01);

            & > .nav-top_icon svg,
            & + .nav-top_icon svg,
            & + .nav-primary_icon svg {
                fill:map-get($colors, _04-01);
            }
        }
    }

    &.is-current {
        color:map-get($colors, _04-01);
    }
}
.nav-top_link {
    @include flex-row;
    align-items:center;

    & > .nav-top_icon {
        margin-left:9px;
    }

    @media screen and (max-width:768px) {
        font-weight:700;
    }
}
.nav-primary_link {
    font-weight:700;
}


/*
 * Icon
 */

.nav-top_icon, .nav-top_icon svg {
    width:14px; height:14px;

    @media screen and (max-width:768px) {
        width:10px; height:10px;
    }
}
.nav-primary_icon, .nav-primary_icon svg {
    width:10px; height:10px;
}
.nav-top_icon,
.nav-primary_icon {
    @include relative;

    &:before {
        @include animate;
        @include absolute(-1);
        content:"";
        top:50%; left:50%;
        width:48px; height:48px;
        background:rgba(map-get($colors, _01-05), .48);
        border-radius:100%;
        transform:translate(-50%, -50%);
        opacity:0;

        @media screen and (max-width:768px) {
            width:24px; height:24px;
        }
    }

    @media screen and (min-width:769px) {
        &:hover,
        &:focus,
        &:active {

            &:before {
                opacity:1;
            }

            svg {
                fill:map-get($colors, _04-01);
            }

            & + .nav-top_sublist,
            & + .nav-primary_sublist {
                opacity:1;
                visibility:visible;
                transform:translateY(0);
            }
        }
    }
}
    .nav-top_icon svg,
    .nav-primary_icon svg {
        @include animate;
        display:block;
        fill:map-get($colors, _01-01);
    }


/*
 * Sublist
 */

.nav-top_sublist,
.nav-primary_sublist {

    @media screen and (min-width:769px) {
        @include absolute(2);
        // width:calc(100% + 38px); min-width:222px; max-width:312px;
        width:max-content;
        background:#FFF;
        border:1px solid map-get($colors, _01-05);
        opacity:0;
        visibility:hidden;
        transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, transform 300ms ease-in-out;
    }
    @media screen and (max-width:768px) {
        display:none;
        margin-top:12px; padding-left:12px;
        width:100%;
        border-left:2px solid map-get($colors, _01-05);
        box-sizing:border-box;
    }

    &.open {

        @media screen and (min-width:769px) {
            opacity:1;
            visibility:visible;
            transform:translateY(0);
        }
        @media screen and (max-width:768px) {
            display:block;
        }
    }
}


/*
 * Subitem
 */

.nav-top_subitem,
.nav-primary_subitem {
    @include relative;
    align-items:center;
    flex-shrink:0;

    &:not(:first-child) {

        @media screen and (min-width:769px) {
            border-top:1px solid map-get($colors, _01-05);
        }
        @media screen and (max-width:768px) {
            padding-top:12px;
        }
    }

    &.has-sublist {

        @media screen and (min-width:769px) {
            padding-right:18px;
        }

        & > .nav-primary_sublink {
            padding-right:0;
        }

        @media screen and (min-width:769px) {
            &:after {
                top:0; left:100%; bottom:0;
                width:4px;
            }

            &:hover > .nav-top_sublist, &:focus > .nav-top_sublist,
            &:hover > .nav-primary_sublist, &:focus > .nav-primary_sublist {
                transform:translateX(0);
            }
        }
    }

    & > .nav-top_sublist,
    & > .nav-primary_sublist {

        @media screen and (min-width:769px) {
            top:-1px; left:calc(100% + 4px);
            transform:translateX(6px);
        }
    }

    & > .nav-top_icon,
    & > .nav-primary_icon {

        @media screen and (max-width:768px) {
            transform:rotate(90deg);
        }
    }
}


/*
 * Sublink
 */

.nav-top_sublink,
.nav-primary_sublink {
    display:block;

    @media screen and (min-width:769px) {
        flex:1 1 auto;
        padding:9px 18px;
    }
}


/*
 * Mobile
 */

.nav-mobile {

    @media screen and (max-width:768px) {
        @include flex-column;
    }
}
    .nav-mobile_header {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
        position:fixed;
        top:0; left:0; right:0;
        z-index:3;
        padding:12px 24px;
        background:#FFF;
        border-bottom:1px solid map-get($colors, _01-05);
    }
    .nav-mobile_content {
        @include flex-column;
        flex:1 1 auto;
        margin-top:map-get($header_height, _mobile); padding-bottom:42px;

        .header-cta.btn {
            position:fixed;
            left:0; right:0; bottom:0;
            z-index:2;
            width:100%;
        }
    }
        .nav-mobile_block {
            padding:24px;

            &:not(:last-child) {
                border-bottom:1px solid map-get($colors, _01-05);
            }
        }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SWIPER PAGINATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.slider-pagination {
    left:auto; bottom:auto;
    width:auto;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SWIPER SLIDER PREV/NEXT ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.slider-prev,
.slider-next {
    @include animate;
    display:block;
    width:26px; height:48px;

    @media screen and (max-width:768px) {
        width:24px; height:24px;
    }

    svg {
        @include animate;
        display:block;
        width:26px; height:48px;
        fill:map-get($colors, _01-01);

        @media screen and (max-width:768px) {
            width:24px; height:24px;
        }
    }

    @media screen and (min-width:769px) {
        &:not(:disabled):hover,
        &:not(:disabled):focus {
            color:map-get($colors, _04-01);

            svg {
                fill:map-get($colors, _04-01);
            }
        }
    }

    &:disabled {
        opacity:.12;
        cursor:default;
    }
}
.slider-prev {
    margin-right:48px;

    @media screen and (max-width:768px) {
        margin-right:24px;

        svg {
            transform:rotate(180deg);
        }
    }
}
.slider-next {
    margin-left:48px;

    @media screen and (min-width:769px) {
        svg {
            transform:rotate(180deg);
        }
    }
    @media screen and (max-width:768px) {
        margin-left:24px;
    }
}
