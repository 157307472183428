/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: INSTAGRAM FEED ----------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_instagram-feed {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }
}
