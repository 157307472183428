/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.header {
    position:fixed;
    top:0; left:24px; right:24px;
    z-index:99;
    line-height:map-get($line_heights, _xsmall);
    font-size:map-get($font_sizes, _xsmall);

    @media screen and (max-width:768px) {
        left:0; right:0;
    }
}
    .header-content {
        background:#FFF;

        .nav-primary_list {
            width: 100%;

            .nav-item, .subnav {
                display: inline-block;
                padding: 24px;
            }
        }
    }
        .header-top {
            @include flex-row;
            @include relative(2);
            justify-content:space-between;
            align-items:center;
            padding:18px 48px 14px;

            @media screen and (max-width:1259px) and (min-width:769px) {
                flex-wrap:wrap;
                padding:6px 6px 14px;
            }
            @media screen and (max-width:768px) {
                padding:12px 24px;
            }
        }
            .header-top_lhc {
                flex-direction:row !important;
                align-items:center;

                @media screen and (max-width:1259px) and (min-width:769px) {
                    padding-right:24px;
                }
            }
            .header-top_mc {
                @include flex-column;
                align-items:center;
                flex:1 1 auto;

                @media screen and (min-width:769px) {
                    padding:0 48px;
                }
                @media screen and (max-width:1300px) and (min-width:1260px) {
                    padding:0 24px;
                }
                @media screen and (max-width:1259px) and (min-width:769px) {
                    order:3;
                    width:100%;
                }
                @media screen and (max-width:768px) {
                    flex-direction:row !important;
                    justify-content:space-between;
                    align-items:center;
                }
            }
            .header-top_rhc {

                @media screen and (max-width:1259px) and (min-width:951px) {
                    padding:0 42px 0 24px;
                }
                @media screen and (max-width:950px) and (min-width:769px) {
                    padding-right:18px;
                }
            }
        .header-middle {
            @include relative;
            // margin-top:-12px;

            &:before {
                @include absolute(-1);
                content:"";
                top:50%; left:0; right:0;
                height:1px;
                background:map-get($colors, _01-05);
            }
        }
        .header-bottom {
            @include relative;
            padding:18px 48px;

            @media screen and (max-width:950px) and (min-width:769px) {
                padding:18px 24px;
            }
        }


/*
 * CTAs
 */

.header-cta {
    flex-shrink:0;

    &:not(:last-child) {
        margin-right:32px;

        @media screen and (max-width:1347px) and (min-width:769px) {
            margin-right:24px;
        }
    }
}
a.header-cta {
    @include animate;

    @media screen and (min-width:769px) {
        &:not(:disabled):hover,
        &:not(:disabled):focus {
            color:map-get($colors, _04-01);
        }
    }
}


/*
 * Triggers
 */

.header-trigger {

    @media screen and (max-width:768px) {
        @include flex-column;
        @include relative;
        justify-content:center;
        align-items:center;
        width:24px; height:24px;

        svg {
            @include animate;
            display:block;
            width:18px; height:18px;
            fill:map-get($colors, _01-01);
        }
    }
}


/*
 * Logo
 */

.header-logo {
    display:block;

    @media screen and (max-width:768px) {
        margin:0 auto; padding:0 24px;
    }
}
.header-logo,
.header-logo svg {
    width:auto; height:40px;

    @media screen and (max-width:768px) {
        height:50px;
    }
}
.header-logo_strapline,
.header-logo_strapline svg {
    display:block;
    width:auto; height:8.6px;
}
.header-logo_strapline {
    padding:0 24px;
    background:#FFF;
}
    .header-logo svg,
    .header-logo_strapline svg {
        fill:map-get($colors, _01-01);
    }
