.finish-chooser-modal {

    .finish-result {
        @include animate;
        @include absolute;
        opacity:0;
        visibility:visible;

        &.show {
            @include relative(2);
            opacity:1;
            visibility:visible;
        }
    }

    /*
    .finish-result {
        @include absolute;
        top: 0;
        bottom: 0;
        padding-top: 16px;
        opacity: 0;
        visibility: hidden;

        .select-finish-button {
            margin-top: 24px;
        }
    }
    */

    .modal_btn-close {
        position:static;
    }
}
    .finish-chooser-modal_heading-wrapper {
        @include flex-row;
        justify-content:space-between;
        align-items:center;
    }
