/* ------------------------------------------------------------------------------------------------------------------------ */
/* COVERAGE CALCULATOR ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.coverage-calculator_modal {

    .form-intro {

        @media screen and (max-width:768px) {
            text-align:left;
        }
    }
}

.coverage-calculator_wrapper {
    margin:48px auto 0;
    width:100%; max-width:map-get($widths, _medium);
    // overflow-x:auto;

    @media screen and (max-width:768px) {
        margin-top:24px;
    }
}

    .coverage-calculator {
        width:100%;
        line-height:1.3;

        @media screen and (min-width:769px) {
            min-width:600px;
            border-collapse:separate; border-spacing:2px;
        }
        @media screen and (max-width:768px) {
            @include flex-column;
            border-collapse:collapse;
        }

        th,
        td {

            @media screen and (min-width:769px) {
                background:map-get($colors, _01-05);
                vertical-align:middle;
            }

            &:not(.no-padding) {

                @media screen and (min-width:769px) {
                    padding:14px 18px;
                }
            }
        }
        th {
            text-align:left;
        }

        .field:not(.field_no-border) {
            border:none;

            @media screen and (min-width:769px) {
                margin-top:0;
            }
        }

        tr:not(.coverage-calculator_bottom) {

            th {
                line-height:map-get($line_heights, _small);
                font-size:map-get($font_sizes, _small);
            }
        }
        tr.coverage-calculator_bottom {

            th,
            td {
                color:#FFF;

                @media screen and (min-width:769px) {
                    background:map-get($colors, _02-01);
                }
            }
            th {
                font-weight:700;

                small {
                    color:rgba(#FFF, .64);
                    font-weight:400;
                }
            }

            & + tr.coverage-calculator_bottom {

                @media screen and (max-width:768px) {
                    flex-direction:row;
                    padding-top:0;

                    th {
                        flex:1 1 auto;
                        padding-right:18px;
                    }
                }
            }
        }

        @media screen and (max-width:768px) {

            tfoot tr {
                @include flex-column;

                &:first-child {
                    margin-bottom:2px;
                }
                &:not(:first-child) {
                    padding:18px;
                    background:map-get($colors, _02-01);
                }

                .field {
                    margin-top:9px;
                }
            }
        }
    }

.coverage-calculator_wall {

    @media screen and (max-width:768px) {
        @include flex-row;
        @include relative;
        flex-wrap:wrap;
        justify-content:space-between;
        margin-bottom:2px; padding:18px;
        background:map-get($colors, _01-05);

        td {
            width:100%;

            &:first-child {
                font-weight:700;
            }
            &:not(:first-child) {
                padding:9px 18px 0 0;
                width:35%;

                span {
                    display:block;
                }
                .field {
                    margin-top:9px;
                }
            }
            &:last-child {
                padding-right:0;
                width:calc(30% - 36px);
            }

            .js_sqm {
                padding-top:18px;
            }
        }
    }

    &:first-child {

        .btn_remove-wall {
            display:none !important;
            opacity:0;
            visibility:hidden;
        }
    }

    td:last-child {

        @media screen and (min-width:769px) {
            @include relative;
        }
    }
}

.btn_add-wall {
    justify-content:center;
    width:100%;
}

.btn_remove-wall {
    @include absolute;
    @include flex-column;
    justify-content:center;
    align-items:center;
    width:32px; height:32px;
    background:#FFF !important;
    border-radius:100%;

    @media screen and (min-width:769px) {
        top:50%; right:-18px;
        border:2px solid map-get($colors, _01-05);
        transform:translateY(-50%);
    }
    @media screen and (max-width:768px) {
        top:6px; right:-16px;
        background:map-get($colors, _01-01) !important;
    }

    svg {
        @include animate;
        display:block;
        width:14px; height:14px;
        fill:map-get($colors, _01-01);

        @media screen and (min-width:769px) {
            &:not(:disabled):hover,
            &:not(:disabled):focus {
                fill:map-get($colors, _04-01);
            }
        }
        @media screen and (max-width:768px) {
            fill:#FFF;
        }
    }
}

.form-bottom {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width:768px) {
        @include flex-column;
        flex-direction: column-reverse;
        .coverage-calculator_select-amount {
            padding-bottom: 24px;
        }
    }
}