/* ------------------------------------------------------------------------------------------------------------------------ */
/* BREADCRUMB ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.woocommerce-breadcrumb {
    padding:24px 0;
    border-bottom:1px solid map-get($colors, _01-04);

    a {
        @include animate;
        color:map-get($colors, _01-01);

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _04-01);
            }
        }
    }
}

/*
.woocommerce-breadcrumb-wrapper {
    display:flex;

    .woocommerce-breadcrumb {
        display:flex;
        align-items:center;
        margin-bottom:0; padding:19px 24px;
        color:#262626;
        font-weight:600;
        border-bottom:2px solid #e6e6e6;

        a {
            position:relative;
            color:#999;
            font-weight:400;

            &:last-child:after {

            }
        }

        .chevron {
            padding:0 12px;
            width:5px; height:8px;
            fill:#999;
            box-sizing:content-box;
        }
    }

    @media screen and (max-width:768px) {
        display: none;
    }
}
*/
