/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOUR COLLECTION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.colour-collection {
    margin-top:24px;
    justify-content: center;

    @media screen and (max-width:768px) {
        margin-top:4px; margin-left:-2px !important; margin-right:-2px !important; padding-left:24px; padding-right:24px;
    }

    & > .column {

        @media screen and (max-width:1024px) and (min-width:769px) {
            width:(3 / 12) * 100%;
        }
        @media screen and (max-width:768px) {
            padding-left:2px !important; padding-right:2px !important;
            width:(4 / 12) * 100%;
        }
    }

    &:not(.archive-products) {
        margin-top:-72px;

        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-top:-24px;
        }
        @media screen and (max-width:768px) {
            margin-top:-4px;
        }
    }
}


/*
 * Card
 */

.colour-card {
    flex:1 1 auto;
    margin-top:24px;
    background:#FFF;

    @media screen and (min-width:769px) {
        &:hover,
        &:focus {

            .colour-card_btn {
                opacity:1;
                visibility:visible;
            }
        }
    }
    @media screen and (max-width:768px) {
        margin-top:4px;
    }

    &.has-open-tooltip .colour-card_top {
        z-index:3;

        .colour-card_btn {
            opacity:1;
            visibility:visible;

            & + .colour-card_tooltip {
                width:auto; height:auto;

                &:before,
                .colour-card_tooltip-content {
                    opacity:1;
                    visibility:visible;
                }
            }

            @media screen and (min-width:769px) {
                z-index:102;
            }
        }
        .colour-card_tooltip .colour-card_tooltip-content {
            z-index:101;
        }
    }

    &.is-closing-tooltip .colour-card_top {
        z-index:2;

        @media screen and (max-width:768px) {
            z-index:3;
        }

        .colour-card_btn {

            & + .colour-card_tooltip {
                width:auto; height:auto;

                &:before,
                .colour-card_tooltip-content {
                    opacity:0;
                    visibility:hidden;
                }
            }

            @media screen and (min-width:769px) {
                z-index:101;
            }
        }
        .colour-card_tooltip .colour-card_tooltip-content {
            z-index:100;
        }
    }
}

    .colour-card_top {
        @include relative;
    }
    .colour-card_bottom {
        padding:24px 24px 32px;
        font-weight:700;
        text-align:center;
        word-wrap: break-word;

        @media screen and (max-width:768px) {
            padding:16px 6px 18px;
            font-size:14px;
        }
    }

        .colour-card_number {
            @include relative;
            margin-top:-12px;
            line-height:1;
            font-size:map-get($font_sizes, _small);

            @media screen and (max-width:768px) {
                margin-top:-8px;
            }

            &:before {
                @include absolute(-1);
                content:"";
                top:50%; left:0; right:0;
                height:1px;
                background:map-get($colors, _01-05);
            }

            span {
                padding:0 12px;
                background:#FFF;
            }
        }

        .colour-card_heading {
            line-height:1.2;

            &:not(:first-child) {
                padding-top:9px;
            }
        }


/*
 * Card Link
 */

.colour-card_link {
    @include flex-column;
    @include full-size(2);
    justify-content:center;
    align-items:center;

    .btn {
        width:calc(100% - 24px);
        font-size:12px !important;
        opacity:0;

        @media screen and (max-width:768px) {
            width:100%; height:100%;
        }

        & + .btn {
            margin-top:6px;

            @media screen and (max-width:768px) {
                display:none;
            }
        }
    }

    @media screen and (min-width:769px) {
        &:hover .btn,
        &:focus .btn {
            opacity:1;
        }
    }
}


/*
 * Card Actions
 */

.colour-card_actions {
    @include flex-column;
    @include full-size(2);
    justify-content:center;
    align-items:center;
}
    .colour-card_btn {
        @include animate;
        @include flex-column;
        justify-content:center;
        align-items:center;
        width:48px; height:48px;
        background:map-get($colors, _01-01) !important;
        border-radius:100%;

        @media screen and (min-width:769px) {
            opacity:0;
            visibility:hidden;

            &:hover + .colour-card_tooltip:before,
            &:focus + .colour-card_tooltip:before {
                opacity:1;
                visibility:visible;
            }
        }

        svg {
            width:18px; height:18px;
            fill:map-get($colors, _01-05);
        }
    }
    .colour-card_tooltip {
        width:0; height:0;

        @media screen and (min-width:769px) {
            @include absolute(-1);
            top:50%; left:50%;
            transform:translateY(-50%);

            &:before {
                @include absolute(-2);
                content:"";
                top:50%; left:-32px;
                width:64px; height:64px;
                background:#FFF;
                border:1px solid map-get($colors, _01-04);
                border-radius:100%;
                box-sizing:border-box;
                transform:translateY(-50%);
                opacity:0;
                visibility:hidden;
                transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out;
            }

            &.overflows-right {
                left:auto; right:50%;

                &:before {
                    left:auto; right:-32px;
                }

                .colour-card_tooltip-content:after {
                    left:auto; right:-1px;
                }

                &.overflows-left {
                    transform:translate(50%, -100%);

                    &:before {
                        top:auto; right:50%; bottom:-32px;
                        transform:translateX(50%);
                    }

                    .colour-card_tooltip-content:after {
                        top:auto; left:50%; right:auto; bottom:-1px;
                        width:62px; height:1px;
                        transform:translateX(-50%);
                    }
                }
            }
        }
        @media screen and (max-width:768px) {
            position:fixed;
            top:50%; left:6px; right:6px;
            transform:translateY(-50%);
        }
    }
        .colour-card_tooltip-content {
            @include relative;
            background:#FFF;
            border:1px solid map-get($colors, _01-04);
            opacity:0;
            visibility:hidden;
            transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out;

            @media screen and (min-width:769px) {
                padding:32px;
                width:460px;

                &:after {
                    @include absolute;
                    content:"";
                    top:50%; left:-1px;
                    background:#FFF;
                    width:1px; height:62px;
                    transform:translateY(-50%);
                }
            }
            @media screen and (max-width:768px) {
                padding:24px;
            }
        }
            .colour-card_tooltip-heading,
            .colour-card_tooltip-product,
            .colour-card_tooltip-text {
                padding-right:24px;
            }
            .colour-card_tooltip-product {
                padding-top:3px;
                color:map-get($colors, _03-02);
                font-style:italic;
            }
            .colour-card_tooltip-text {
                padding-top:9px;
                line-height:map-get($line_heights, _xsmall);
                font-size:map-get($font_sizes, _small);
            }
            .colour-card_tooltip-btns {
                padding-top:24px;

                &.direction-row {

                    @media screen and (min-width:769px) {
                        flex-wrap:wrap;
                        justify-content:space-between;
                    }
                    @media screen and (max-width:768px) {
                        align-items:center;

                        .btn:not(.btn-02) {
                            width:100%;
                        }
                    }
                }

                & + .colour-card_tooltip-btns {
                    margin-top:24px; padding-top:24px;
                    border-top:2px solid map-get($colors, _01-05);
                }

                .btn.btn-02 {
                    text-decoration:none !important;
                }
            }
            .colour-card_tooltip-close {
                @include absolute;
                top:16px; right:16px;
                width:18px; height:18px;

                svg {
                    @include animate;
                    width:18px; height:18px;
                    fill:map-get($colors, _01-01);
                }

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover svg,
                    &:not(:disabled):focus svg {
                        fill:map-get($colors, _04-01);
                    }
                }
            }

body {

    &.page-template-order-samples .colour-card_link {
        display:none !important;
    }

    &:not(.page-template-order-samples) {

        .colour-card_actions {
            display:none !important;
        }
    }
}
