/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGIN ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

body.woocommerce-account .woocommerce {

    & > .col2-set {

        @media screen and (min-width:769px) {
            @include flex-row;
            justify-content:space-between;
        }

        & > .col-1,
        & > .col-2 {
            padding:48px;
            width:calc(50% - 48px);
            // border:1px solid map-get($colors, _01-04);
            box-sizing:border-box;

            &:first-child {
                background:#FFF;

                .woocommerce-form-row {
                    border:1px solid map-get($colors, _01-04);
                }
            }
            &:last-child {
                color:#FFF;
                background:map-get($colors, _02-01);

                .woocommerce-form-row {
                    border:1px solid map-get($colors, _02-01);
                }
                .woocommerce-privacy-policy-text a {
                    @include animate;
                    color:#FFF;
                    font-weight:700;

                    @media screen and (min-width:769px) {
                        &:hover,
                        &:focus {
                            color:map-get($colors, _04-01);
                        }
                    }
                }
            }

            @media screen and (max-width:768px) {
                padding:24px;
                width:100%;

                &:not(:first-child) {
                    margin-top:48px;
                }
            }

            h2 {
                @include heading(1.3, 1.5, 32px, 24px);
                font-weight:700;
            }

            .woocommerce-form {
                padding-top:24px;

                .form-row {
                    flex-direction:column !important;
                    align-items:flex-start !important;

                    &:not(:first-child) {
                        margin-top:6px;
                    }

                    & > label:first-child:not(.woocommerce-form__label-for-checkbox) {
                        @include relative(2);
                        display:block;
                        margin-bottom:-9px;
                        padding:9px 18px 0;
                        line-height:map-get($line_heights, _xsmall);
                        color:map-get($colors, _01-01);
                        font-size:map-get($font_sizes, _xsmall);
                        background:#FFF;
                    }

                    & > label.woocommerce-form__label-for-checkbox {
                        @include flex-row;
                        align-items:center;

                        & > input::before {
                            border:1px solid map-get($colors, _01-04);
                            box-sizing:border-box;
                        }
                        & > span {
                            cursor:pointer;
                        }
                    }

                    &:not(.form-row-wide) {
                        @include flex-row;
                        justify-content:space-between;
                        align-items:center;
                    }

                    .woocommerce-form-login__submit,
                    .woocommerce-form-register__submit {
                        width:100%;
                    }
                    .woocommerce-form-login__submit {
                        margin-top:24px;
                    }

                    &.automatewoo-optin {
                        .woocommerce-form__label {
                            padding-bottom: 24px;
                        }
                    }
                }

                .woocommerce-password-strength,
                .woocommerce-privacy-policy-text {
                    padding-top:24px;
                }
                .woocommerce-password-strength,
                .woocommerce-password-hint,
                .woocommerce-privacy-policy-text {
                    line-height:map-get($line_heights, _xsmall);
                    font-size:map-get($font_sizes, _small);
                }
                .woocommerce-password-strength {
                    font-weight:700;
                }
                .woocommerce-password-hint {
                    display:block;
                }
                .woocommerce-privacy-policy-text + .form-row {
                    @include flex-row;
                    justify-content:flex-end;
                    padding-top:24px;
                }
            }
        }
    }

    .input-text {
        @include woo-input-text;
        height:auto;
        border:none !important;
    }

    .button {
        @include woo-button;
    }
}


/*
 * Lost Password Link
 */

.lost_password {
    @include flex-column;
    align-items:flex-start;
    padding-top:12px;

    a {
        @include animate;
        line-height:map-get($line_heights, _xsmall);
        color:map-get($colors, _03-01);
        font-size:map-get($font_sizes, _small);
        font-weight:700;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _04-01);
            }
        }
    }
}


/*
 * Lost Password
 */

.lost_reset_password {
    @include flex-row;
    flex-wrap:wrap;

    @media screen and (min-width:769px) {
        margin:0 auto; padding:48px;
        width:calc(50% - 48px);
        border:1px solid map-get($colors, _01-04);
        box-sizing:border-box;
    }

    & > p:first-child {
        padding-bottom:18px;
        width:100%;
    }

    .form-row {

        &:not(:first-child) {
            padding-top:6px;

            @media screen and (max-width:768px) {
                width:100%;
            }
        }

        &.form-row-first {
            flex:1 1 auto;
            margin-right:6px;
        }

        & > label:first-child {
            @include relative(2);
            display:block;
            margin-bottom:-9px;
            padding:9px 18px 0;
            line-height:map-get($line_heights, _xsmall);
            color:map-get($colors, _01-01);
            font-size:map-get($font_sizes, _xsmall);
            background:#FFF;
        }
    }

    .button {
        height:100%;

        @media screen and (max-width:768px) {
            width:100%;
        }
    }
}
