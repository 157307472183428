/* ------------------------------------------------------------------------------------------------------------------------ */
/* CART (HEADER) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.header-cart.js-panel {

    @media screen and (max-width:768px) {
        @include flex-column;
        flex:1 1 auto;
        position:static;
        opacity:1;
        visibility:visible;
        width:100%; max-width:none;
        box-shadow:none;
        transform:none;
    }
}
    .header-cart_list {
        margin-bottom:12px;
        border-bottom:1px solid #FFF;

        @media screen and (min-width:769px) {
            max-height:320px;
            overflow-y:auto;
        }
    }
        .header-cart_list-empty {
            padding-bottom:24px;
            line-height:map-get($line_heights, _base);
            font-size:map-get($font_sizes, _base);
            text-align:center;
        }


/*
 * Item
 */

.header-cart_item {
    @include flex-row;
    padding-bottom:12px;

    &:not(:last-child) {
        margin-bottom:12px;
        border-bottom:1px solid #FFF;
    }
}

    .header-cart_item_remove {
        @include relative;
        @include flex-column;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        width:24px; height:24px;

        &:before {
            @include animate;
            @include absolute(-1);
            content:"";
            width:24px; height:24px;
            background:rgba(#000, .12);
            border-radius:100%;

            @media screen and (min-width:769px) {
                opacity:0;
            }
        }

        svg {
            @include animate;
            display:block;
            width:10px; height:10px;
            fill:map-get($colors, _01-01);
        }

        @media screen and (min-width:769px) {
            &:not(:disabled):hover,
            &:not(:disabled):focus {

                &:before {
                    opacity:1;
                }

                svg {
                    fill:map-get($colors, _04-01);
                }
            }
        }

        &.has_ajax-loader:after {
            left:50%; right:auto;
            margin-top:-6px; margin-left:-6px;
            width:12px; height:12px;
            border-width:1px; border-color:map-get($colors, _01-01); border-top-color:transparent;
        }

        &.is_ajax-loading svg {
            opacity:0;
            visibility:hidden;
        }

        &:disabled {
            opacity:.24;
            cursor:default;
        }
    }

    .header-cart_item_meta {
        flex:1 1 auto;
        padding:0 12px 0 6px;
        line-height:1.2;
    }
        .header-cart_item_title {
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
            font-weight:400;

            a {
                @include animate;

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover,
                    &:not(:disabled):focus {
                        color:map-get($colors, _04-01);
                    }
                }
            }
        }

    .header-cart_item_thumb,
    .header-cart_item_thumb img {
        display:block;
        width:48px; height:48px;
    }


/*
 * Total
 */

.header-cart_total {
    line-height:map-get($line_heights, _small);
    font-size:map-get($font_sizes, _small);
    font-weight:700;
    text-align:center;
}


/*
 * Buttons
 */

.header-cart_buttons {
    padding-top:18px !important;

    @media screen and (max-width:768px) {
        margin-top:auto;
    }
}


/*
 * Trigger
 */

 .header-cart_trigger {
}
    .header-cart_icon,
    .header-cart_icon svg {
        width:16px; height:16px;
    }
    .header-cart_count {

        @media screen and (min-width:769px) {
            padding-left:8px;
        }
        @media screen and (max-width:768px) {
            @include flex-column;
            @include absolute;
            justify-content:center;
            align-items:center;
            top:-12px; right:-12px;
            padding:4px;
            min-width:24px; height:24px;
            line-height:1;
            color:#FFF;
            font-size:12px;
            background:map-get($colors, _04-01);
            border-radius:100%;
            box-sizing:border-box;
        }
    }
