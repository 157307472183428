/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.lazyload-wrapper {
    @include relative;
    display:block;

    picture,
    img {
        @include animate;
        @include relative;
        @include full-width;
        opacity:0;

        &.lazyloaded {
            opacity:1;
        }
    }
    picture.lazyloaded img {
        opacity:1;
    }
}
    .lazyload-caption {
    }


/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.no-js {

    .lazyload-wrapper picture,
    .lazyload-wrapper img {
        opacity:1;
    }
}
