.pb-block_painted-sheet-grid {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    .ps-item {
        @media screen and (min-width:769px) {
            margin-top: 24px;
        }

        .btn {
            font-size:12px !important;

            @media screen and (min-width:769px) {
                display: none;
            }
        }

        .ps-item_top {
            @include relative(1);

            .ps-item_actions {
                @include flex-column;
                @include full-size(2);
                justify-content:center;
                align-items:center;

                @media screen and (max-width:768px) {
                    display: none;
                }

                .btn {
                    display: flex;
                    width:calc(100% - 24px);
                    font-size:12px !important;
                    opacity:0;

                    &.is_ajax-loading {
                        opacity:1;
                    }

                    & + .btn {
                        margin-top:6px;
                    }
                }

                @media screen and (min-width:769px) {
                    &:hover .btn,
                    &:focus .btn {
                        opacity:1;
                    }
                }
            }
        }

        .ps-item_bottom {
            background-color: map-get($colors, _white);
            padding: 24px 24px 32px;
            font-weight: 700;
            text-align: center;
            word-wrap: break-word;
        }
    }

    @media screen and (max-width: 768px) {
        .painted-sheet-grid > .column {
            padding-left: 6px !important;
            padding-right: 6px !important;
            width: 50%;
        }
    }
}
