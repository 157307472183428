/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: PARTNER MAP -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_partner-map {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }

    .pb-block_content > .grid {

        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-left:-24px; margin-right:-24px;

            .column {
                padding-left:24px; padding-right:24px;
            }
        }
    }
}
