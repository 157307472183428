/*------------------------------------*\
    $COOKIE-CONTROLLER
\*------------------------------------*/

/**
 * Setup your theme colours and sizes here. You can also edit the
 * namespace to avoid collisons with other classes in your existing
 * CSS. This must also be done in the JS options when initialising
 * the plugin itself, using the 'cssNamespace' option.
 */

 $cc-namespace:               'cc';

 $cc-spacing-unit:            24px;

 // Colours
 $cc-overlay-colour:          rgba(#000, .88);
 $cc-content-background:      #fff;
 $cc-toggle-background:       #078092;
 $cc-toggle-hover:            darken($cc-toggle-background, 12%);
 $cc-toggle-colour:           #fff;
 $cc-btn-background:          #000;
 $cc-btn-hover:               darken($cc-btn-background, 12%);
 $cc-btn-colour:              #fff;
 $cc-btn-accept-background:   #38803A;
 $cc-btn-accept-hover:        darken($cc-btn-accept-background, 12%);
 $cc-border-colour:           #ddd;
 $cc-tooltip-background:      rgba(#000, .88);
 $cc-banner-background:       #fff;
 $cc-banner-fixed-background: rgba(#fff, .88);

 // Sizes
 $cc-overlay-padding:         $cc-spacing-unit * 2;
 $cc-content-padding:         $cc-spacing-unit;
 $cc-content-width:           600px;
 $cc-content-radius:          6px;
 $cc-btn-padding:             12px 16px;
 $cc-btn-radius:              6px;

 // Misc.
 $cc-anim-speed:              300ms;


 /**
  * Prevent page from scrolling
  */

 .has-#{$cc-namespace}-lock {
     &,
     body {
         overflow: hidden;
     }
 }


 /**
  * Toggle
  */

 .#{$cc-namespace}-toggle {
     position: fixed;
     bottom: $cc-spacing-unit * 0.5;
     left: $cc-spacing-unit * 0.5;
     z-index: 101;
     border: none;
     cursor: pointer;
     width: $cc-spacing-unit * 2;
     height: $cc-spacing-unit * 2;
     background-color: $cc-toggle-background !important;
     color: $cc-toggle-colour;
     border-radius: 99px;
     transition: background-color $cc-anim-speed;

     .#{$cc-namespace}-icon {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: 22px;
         height: 22px;
     }

     &:hover {
         background-color: $cc-toggle-hover !important;

         /*
         .#{$cc-namespace}-toggle-label {
             opacity: 1;
             visibility: visible;
             transform: translate(5px, -50%);
             transition-delay: 0s;
         }
         */
     }
 }

 .#{$cc-namespace}-toggle-label {
     position: absolute;
     z-index: -1;
     display: inline-block;
     background-color: $cc-tooltip-background !important;
     padding: 8px 10px 10px;
     left: calc(100% + 5px);
     top: 50%;
     font-size: 12px;
     text-align: left;
     white-space: nowrap;
     border-radius: 3px;
     // opacity: 0;
     // visibility: hidden;
     // transform: translateY(-50%);
     // transition: opacity $cc-anim-speed, transform $cc-anim-speed, visibility 0s $cc-anim-speed;

     opacity: 1;
     visibility: visible;
     transform: translate(5px, -50%);
     transition-delay: 0s;

     &::before {
         content: "";
         position: absolute;
         right: 100%;
         top: 50%;
         transform: translateY(-50%);
         border: 6px solid transparent;
         border-right-color: $cc-tooltip-background;
     }
 }


 /**
  * Overlay
  */

 .#{$cc-namespace} {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 103;
     width: 100%;
     height: 100%;
     overflow: hidden;
     padding: $cc-overlay-padding 6px 6px;
     background-color: $cc-overlay-colour !important;
     text-align: center;
     opacity: 0;
     visibility: hidden;
     transition: opacity $cc-anim-speed, visibility 0s $cc-anim-speed;

     &.is-active {
         visibility: visible;
         opacity: 1;
         overflow: auto;
         transition-delay: 0s;
         backface-visibility: hidden;
         -webkit-overflow-scrolling: touch;
     }

     &::before {
         content: "";
         display: inline-block;
         vertical-align: middle;
         height: 100%;
         line-height: 100%;
         margin-left: -0.3em;
     }


     /**
      * Scoped, generic styles
      */

     &, &::before, &::after,
     *, *::before, *::after {
         box-sizing: border-box;
     }

     h1, h2, h3, h4, h5, h6,
     p, ul, ol {
         margin: 0 0 $cc-spacing-unit 0;
         padding: 0;
     }

     @media screen and (min-width: 550px) {
         padding: $cc-overlay-padding;
     }
 }

 .#{$cc-namespace}-body {
     position: relative;
     display: inline-block;
     text-align: left;
     vertical-align: middle;
     background-color: $cc-content-background !important;
     border-radius: $cc-content-radius;
     transition: transform $cc-anim-speed ease-in;
     transform: translateY(-12px);

     .is-active > & {
         transform: none;
         transition-timing-function: ease-out;
     }
 }

 .#{$cc-namespace}-close,
 .#{$cc-namespace}-back {
     position: absolute;
     z-index: 1;
     background: none;
     border: none;
     font: inherit;
     text-align: left;
     margin: 0;
     padding: 0;
     cursor: pointer;
     color: rgba(#fff, .8);
     font-size: 0.9em;

     &:hover {
         color: #fff;
     }
 }

 .#{$cc-namespace}-close {
     display: none;
     bottom: 100%;
     right: 0;
     line-height: 48px;

     &.is-active {
         display: block;
     }

     .#{$cc-namespace}-icon {
         margin-left: 6px;
     }
 }

 .#{$cc-namespace}-back {
     width: 48px;
     height: 48px;
     transition: transform $cc-anim-speed ease-in;

     &.is-active {
         transition-timing-function: ease-out;
     }

     .#{$cc-namespace}-icon {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%) rotate(0.25turn);
         transition: transform $cc-anim-speed ease-in-out;
     }

     &:hover {
         .#{$cc-namespace}-icon {
             transform: translate(-75%, -50%) rotate(0.25turn);
         }
     }

     @media screen and (max-width: 549px) {
         bottom: 100%;
         left: 0;
         transform: translateY(100%);
         width: 24px;

         &.is-active {
             transform: none;
         }
     }

     @media screen and (min-width: 550px) {
         top: 50%;
         right: 100%;
         transform: translate(100%, -50%);

         &.is-active {
             transform: translateY(-50%);
         }
     }
 }

 .#{$cc-namespace}-icon {
     display: inline-block;
     vertical-align: middle;
     width: 18px;
     height: 18px;
     fill: currentColor;
 }

 .#{$cc-namespace}-content {
     display: none;
     padding: $cc-content-padding $cc-content-padding 1px;
     max-width: $cc-content-width;
     outline: none;

     &.is-active {
         display: block;
     }
 }

 .#{$cc-namespace}-step-1 {
     text-align: center;
 }

 .#{$cc-namespace}-pinch {
     &#{&} {
         margin-bottom: $cc-spacing-unit / 2;
     }

     @media screen and (max-width: 549px) {
         .#{$cc-namespace}-btn {
             width: 100%;

             &:first-child {
                 margin-bottom: $cc-spacing-unit / 4;
             }
         }
     }

     @media screen and (min-width: 550px) {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         justify-content: space-between;

         .#{$cc-namespace}-btn:not(.#{$cc-namespace}-btn-bare) {
             flex-shrink: 0;
             width: calc(50% - 3px);
         }
         .#{$cc-namespace}-btn-bare {
             margin: 12px auto 0;
         }
     }
 }


 /**
  * Btns
  */

 .#{$cc-namespace}-btn {
     display: inline-block;
     vertical-align: middle;
     font: inherit;
     text-align: center;
     cursor: pointer;
     margin: 0;
     padding: $cc-btn-padding;
     border: none;
     background-color: $cc-btn-background !important;
     color: $cc-btn-colour;
     border-radius: $cc-btn-radius;
     transition: background-color $cc-anim-speed;

     &:hover {
         background-color: $cc-btn-hover !important;
     }
 }

 .#{$cc-namespace}-btn-accept {
     background-color: $cc-btn-accept-background !important;

     &:hover {
         background-color: $cc-btn-accept-hover !important;
     }
 }

 .#{$cc-namespace}-btn-tick {
     position: relative;
     transition: background-color $cc-anim-speed, padding-right $cc-anim-speed ease-in-out $cc-anim-speed;

     .#{$cc-namespace}-icon {
         position: absolute;
         top: 50%;
         right: 22px;
         transform: translate(50%, -50%);
         opacity: 0;
         transition: opacity $cc-anim-speed;
     }

     /*
     &.is-showing-tick {
         padding-right: 44px;
         transition-delay: 0s;

         .#{$cc-namespace}-icon {
             opacity: 1;
             transition-delay: $cc-anim-speed;
         }
     }
     */
 }

 .#{$cc-namespace}-btn-bare {
     padding: 0;
     min-width: 0 !important;
     color: $cc-btn-background;
     background: none !important;
     text-decoration: underline;
     transition: color $cc-anim-speed;

     &:hover {
         color: $cc-btn-accept-background;
         text-decoration: none;
     }

     @media screen and (max-width: 479px) {
         margin-top: 12px;
     }
     @media screen and (min-width: 480px) {
         margin-left: 24px;
     }
 }


 /**
  * Groups
  */

 .#{$cc-namespace}-groups {
     min-height: 250px;
     max-height: 500px;
     overflow-y: scroll;
     border-bottom: 2px solid $cc-border-colour;
     border-top: 2px solid $cc-border-colour;
     margin: 0 (0 - $cc-content-padding) $cc-spacing-unit;
     padding: $cc-spacing-unit $cc-content-padding 0;
 }

 .#{$cc-namespace}-group {
     &:not(:last-child) {
         margin-bottom: $cc-spacing-unit;
         border-bottom: 2px solid $cc-border-colour;
     }
 }


 /**
  * Splits
  */

 .#{$cc-namespace}-split {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     margin-bottom: $cc-spacing-unit / 2;

     &#{&} > * {
         margin-bottom: $cc-spacing-unit / 2;
     }
 }

     .#{$cc-namespace}-split-title {
         flex: 1;

         &#{&} {
             margin-right: $cc-spacing-unit;
         }
     }


 /**
  * iOS Style switches
  */

 .#{$cc-namespace}-switch {
     position: relative;
     display: inline-block;
     cursor: pointer;
 }

 .#{$cc-namespace}-switch-control {
     position: absolute;
     z-index: -1;
     opacity: 0;

     &:checked {
         ~ .#{$cc-namespace}-switch-body {
             border-color: $cc-btn-accept-background;
             box-shadow: inset 0 0 0 18px $cc-btn-accept-background;
             background-color: $cc-btn-accept-background !important;
             color: #fff;
             transition-duration: $cc-anim-speed * 3, $cc-anim-speed, $cc-anim-speed, $cc-anim-speed;

             &::before {
                 transform: translateX(30px);
                 border-color: $cc-btn-accept-background;
             }
         }
     }

     &:focus {
         ~ .#{$cc-namespace}-switch-body {
             outline: 1px dotted;
             outline: -webkit-focus-ring-color auto 5px;
         }
     }
 }

 .#{$cc-namespace}-switch-body {
     position: relative;
     display: inline-flex;
     align-items: center;
     justify-content: space-between;
     font-size: 10px;
     border-radius: 99px;
     width: 66px;
     height: 36px;
     text-transform: uppercase;
     padding-left: 8px;
     padding-right: 6px;
     border: 2px solid $cc-border-colour;
     box-shadow: inset 0 0 0 0 $cc-border-colour;
     transition: background-color $cc-anim-speed, border-color $cc-anim-speed, box-shadow $cc-anim-speed, color $cc-anim-speed;

     &::before {
         content: "";
         position: absolute;
         left: -2px;
         top: -2px;
         width: 36px;
         height: 36px;
         border: 2px solid $cc-border-colour;
         background-color: $cc-content-background !important;
         border-radius: 99px;
         transition: transform $cc-anim-speed;
     }
 }


 /**
  * Tooltips
  */


 .#{$cc-namespace}-tooltip {
     position: relative;

     &.is-active,
     &:hover {
         .#{$cc-namespace}-tooltip-body {
             transform: translateX(-50%);
             visibility: visible;
             opacity: 1;
             transition-delay: 0s;
             transition-timing-function: ease-out;
         }
     }

     &.is-active {
         .#{$cc-namespace}-tooltip-body {
             pointer-events: auto;
         }
     }

     &.hide-tooltip {
         display: none !important;
     }
 }

 .#{$cc-namespace}-tooltip-toggle {
     cursor: help;
     display: inline-block;
     font: inherit;
     margin: 0;
     padding: 0;
     background: none;
     border: none;
     text-decoration: underline;
     opacity: 0.5;
     transition: opacity $cc-anim-speed;

     &:hover {
         opacity: 1;
     }
 }

 .#{$cc-namespace}-tooltip-body {
     position: absolute;
     top: 100%;
     left: 50%;
     z-index: 1;
     transform: translate(-50%, $cc-spacing-unit / 2);
     opacity: 0;
     visibility: hidden;
     width: 360px;
     max-width: calc(100vw - #{$cc-spacing-unit});
     transition: opacity $cc-anim-speed, transform $cc-anim-speed ease-in, visibility 0s $cc-anim-speed;
     background-color: $cc-tooltip-background !important;
     border-radius: 8px;
     pointer-events: none;
     padding: $cc-spacing-unit;
     margin-top: $cc-spacing-unit / 2;
     color: #fff;

     &::before {
         content: "";
         position: absolute;
         bottom: 100%;
         left: 50%;
         transform: translateX(-50%);
         border: 7px solid transparent;
         border-bottom-color: $cc-tooltip-background;
     }

     &#{&} > :last-child {
         margin-bottom: 0;
     }
 }

 .#{$cc-namespace}-tooltip-close {
     position: absolute;
     top: 0;
     right: 0;
     visibility: hidden;
     opacity: 0;
     cursor: pointer;
     display: inline-block;
     font: inherit;
     margin: 0;
     padding: 0;
     background: none;
     border: none;
     width: 32px;
     height: 32px;
     color: rgba(#fff, .75);
     transition: opacity $cc-anim-speed, visibility 0s $cc-anim-speed;

     &:hover {
         color: #fff;
     }

     .#{$cc-namespace}-tooltip.is-active & {
         opacity: 1;
         visibility: visible;
         transition-delay: 0s;
     }

     .#{$cc-namespace}-icon {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: $cc-spacing-unit / 2;
         height: $cc-spacing-unit / 2;
     }
 }


 .#{$cc-namespace}-footer {
     @media screen and (max-width: 549px) {
         .#{$cc-namespace}-btn {
             width: 100%;

             &:first-child {
                 margin-bottom: $cc-spacing-unit / 4;
             }
         }
     }

     @media screen and (min-width: 550px) {
         display: flex;
         flex-direction: row;
         justify-content: space-between;

         .#{$cc-namespace}-btn {
             flex-shrink: 0;
             width: calc(50% - 3px);
         }
     }
 }

 .#{$cc-namespace}-credit {
     position: absolute;
     color: rgba(#fff, .6);
     top: calc(100% + #{$cc-spacing-unit * 0.5});
     left: 0;
     right: 0;
     text-align: center;
     font-size: 0.85rem;

     a {
         color: rgba(#fff, .6);

         &:hover {
             color: #fff;
         }
     }
 }


 /**
  * Banner
  */

 .#{$cc-namespace}-banner-wrapper {
     display: none;
 }

 .#{$cc-namespace}-banner {
     padding: $cc-spacing-unit;
     background-color: $cc-banner-background !important;
 }

 .#{$cc-namespace}-banner-container {
     width: 100%;
     margin-left: auto;
     margin-right: auto;

     @media screen and (min-width: 960px) {
         display: flex;
         justify-content: space-between;
         align-items: center;
     }
 }

 .#{$cc-namespace}-banner-text {
     margin: 0;

     @media screen and (max-width: 959px) {
         margin-bottom: $cc-spacing-unit;
     }
 }

 .#{$cc-namespace}-banner-footer {
     margin: 0;

     @media screen and (max-width: 479px) {
         .#{$cc-namespace}-btn {
             width: 100%;

             &:first-child {
                 margin-bottom: $cc-spacing-unit / 4;
             }
         }
     }

     @media screen and (min-width: 480px) {
         display: flex;
         align-items: center;
         margin-top: -6px;

         .#{$cc-namespace}-btn:not(.#{$cc-namespace}-btn-bare) {
             margin: 6px 3px 0;
             min-width: 240px;
         }
     }
     @media screen and (min-width: 480px) and (max-width: 959px) {
         justify-content: center;
         flex-wrap: wrap;

         .#{$cc-namespace}-btn-bare {
             margin-top: 12px;
             margin-left: 0;
             width: 100%;
         }
     }

     @media screen and (min-width: 960px) {
         margin-left: $cc-spacing-unit;

         .#{$cc-namespace}-btn {
             white-space: nowrap;
         }
     }
 }


 /**
  * Fixed Banner
  */

 .#{$cc-namespace}-banner-fixed {
     display: block;
     position: fixed;
     left: 0;
     top: 100%;
     right: 0;
     transition: transform $cc-anim-speed ease-in;
     z-index: 102;

     &.is-active {
         transform: translateY(-100%);
         transition-timing-function: ease-out;
     }
 }
