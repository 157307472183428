/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.page-builder {
}


/*
 * Block
 */

.pb-block {
    @include relative;
    padding:96px;

    @media screen and (max-width:1220px) and (min-width:769px) {
        padding:48px;
    }
    @media screen and (max-width:768px) {
        padding:48px 24px;
    }

    // Background
    &.pb-block_has-bg {
        @include relative;
        justify-content:center;

        @media screen and (min-width:769px) {
            // min-height:map-get($widths, _xsmall);
        }
    }

    // No Background
    &:not(.pb-block_has-bg) {

        & + .pb-block:not(.pb-block_has-bg) {
            margin-top:-96px !important;

            @media screen and (max-width:1220px) and (min-width:769px) {
                margin-top:-48px !important;
            }
            @media screen and (max-width:768px) {
                margin-top:-48px !important;
            }
        }

        &:last-child {
            padding-bottom:0;
        }
    }
}


/*
 * Background
 */

.pb-block_background {
    @include full-size(-1);
    overflow:hidden;
}
    .pb-block_background_image-wrapper,
    .pb-block_background_image {
        @include full-size;
    }
    .pb-block_background_image {
        position:absolute !important;
        height:100% !important;
        object-fit:cover; font-family:'object-fit:cover;';
    }


/*
 * Header / Content / Footer
 */

.pb-block_header,
.pb-block_content,
.pb-block_footer {

    &:not(:first-child) {
        padding-top:48px;

        @media screen and (max-width:768px) {
            padding-top:24px;
        }
    }
}
.pb-block_header {

    @media screen and (max-width:768px) {
        padding-left:24px; padding-right:24px;
    }
}
