.box-icons-links {
    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
    }
    &__box {
        border: 1px solid black;
        &__link {
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 100%;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            svg {
                stroke: map-get($colors, _01-01);
                fill: map-get($colors, _01-01);
                transition: all 0.2s ease-in-out;
            }
        }
        &__content {
            margin-top: 15px;
        }
    }
    @media only screen and (min-width: 768px) {
        &__box {
            &__link {
                @media (hover) {
                    &:hover {
                        background-color: map-get($colors, _04-01);
                        color: map-get($colors, _white);
                        svg {
                            stroke: map-get($colors, _white);
                            fill: map-get($colors, _white);
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        &__box {
            &__link {
                padding: 20px;
            }
        }
    }
}

.box-icons-links__box:nth-child(2) {
    border-left: none;
    border-right: none;
}
