/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.woocommerce-product {

    @media screen and (max-width:768px) {
        padding-top:0;
    }
}

    .grid .column.woocommerce-product_lhc {

        @media screen and (max-width:1024px) and (min-width:769px) {
            width:43% !important;
        }
        @media screen and (max-width:768px) {
            padding-left:24px !important; padding-right:24px !important;

            .sample-buttons,
            .sample-buttons_info-bottom {
                order: -1;
            }

            .sample-buttons {
                padding-top: 24px;
            }
        }
    }

    .woocommerce-product_rhc {

        @media screen and (max-width:1024px) and (min-width:769px) {
            width:57% !important;
        }
        @media screen and (max-width:768px) {
            order:-1;
        }

        &.is-simple {

            .woocommerce-product-gallery {
                padding:24px;
                background:#FFF;
                border-bottom:1px solid map-get($colors, _01-05);
            }
                .woocommerce-product-gallery__wrapper {
                    justify-content:center;
                }
                    .woocommerce-product-gallery__image {
                        margin-bottom:1px;

                        &:not(:first-child) {
                            margin:1px;
                        }
                    }

            form.cart {
                @include flex-row;
                padding-top:12px;

                @media screen and (max-width:768px) {

                    button {
                        margin-top:0; margin-left:6px;
                        width:auto;
                    }
                }
            }

            .gallery-modal_plus {
                display:none !important;
            }
        }

        &:not(.is-simple) {

            .woocommerce-product-gallery__image:not(:first-child) {
                display:none !important;
            }
        }
    }


/*
 * Title
 */

.product_title {
    padding-top:24px;
}

/*
 * Partner Logo
 */

.partner-logo-thumb_wrapper {
    @include relative;
    @include flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width:768px) {
        justify-content: flex-start;
        margin-top: 48px;
    }

    .partner-logo-thumb {
        width: 40%;
        max-height: 120px;
        border: 1px solid map-get($colors, _01-04);
        padding: 24px;
        object-fit:contain; font-family:'object-fit:contain;';

        @media screen and (max-width:768px) {
            width: 100%;
            box-sizing: border-box;
        }
    }
}


/*
 * Short Description
 */

.woocommerce-product-details__short-description {
    padding-top:32px;

    @media screen and (max-width:768px) {
        padding-top:18px;
    }
}


/*
 * Sample Buttons
 */

.sample-buttons {
    padding-top:48px;

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content: flex-start;
    }
    @media screen and (max-width:768px) {
        margin-top:-6px;
    }

    .btn {
        @include flex-row;
        align-items:center;
        padding:6px 28px 6px 12px;

        &.sample_pot {
            padding: 0 28px 0 12px;
            .btn-icon {
                width:72px; height:72px;
                @media screen and (max-width:768px) {
                    width:28px; height:28px;
                }
                svg {
                    width:72px; height:72px;
                    @media screen and (max-width:768px) {
                        width:28px; height:28px;
                    }
                }
            }
            @media screen and (max-width:768px) {
                padding: 6px 28px 6px 12px;
            }
        }

        &:not(:last-child) {
            margin-right:12px;
        }

        @media screen and (max-width:768px) {
            margin-top:6px;
            width:100%;
        }

        .btn-icon {
            margin-right:12px;
            width:62px; height:62px;

            @media screen and (max-width:768px) {
                width:28px; height:28px;
            }

            svg {
                width:62px; height:62px;
                fill:#FFF;

                @media screen and (max-width:768px) {
                    width:28px; height:28px;
                }
            }
        }

        &.is_ajax-loading {
            padding-right:48px;
        }
    }
}

.sample-buttons_info-bottom {
    padding-top:18px;
    line-height:map-get($line_heights, _small);
    color:map-get($colors, _01-02);
    font-size:map-get($font_sizes, _small);

    @media screen and (max-width:768px) {
        padding-top:12px;
    }
}


/*
 * Attributes
 */

.attribute-container {
    padding-top:48px;
}
    .attribute-label {
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        padding-bottom:9px;
        font-weight:700;
        border-bottom:2px solid map-get($colors, _01-04);
    }
    .attributes {

        ul {
            @include flex-row;
            flex-wrap:wrap;
            margin-top:-6px; padding-top:24px;
            list-style:none;

            li {
                margin:6px 6px 0 0;
                padding:6px 12px;
                line-height:map-get($line_heights, _xsmall);
                font-size:map-get($font_sizes, _xsmall);
                font-weight:700;
                text-transform:uppercase;
                background:#FFF;
            }
        }
    }


/*
 * Description
 */

.woocommerce-tabs {
    padding-top:48px;

    .tabs {
        display:none;
    }

    .woocommerce-Tabs-panel--description h2 {
        @include heading(1.5, map-get($line_heights, _base), 24px, map-get($font_sizes, _base));
        padding-bottom:9px;
        font-weight:700;
        border-bottom:2px solid map-get($colors, _01-04);
    }
}


/*
 * Summary
 */

.summary.entry-summary {
    padding:24px;
    background:#FFF;

    .price {
        @include heading(1, 1, 24px, map-get($font_sizes, _base));
        font-weight:700;
    }

    .quantity {

        @media screen and (min-width:769px) {
            flex:1 1 auto;
            margin-right:6px;
        }

        input {
            @include woo-input-text;
        }
    }

    button {
        @include woo-button;

        @media screen and (max-width:768px) {
            margin-top:6px;
            width:100%;
        }

        &.disabled {
            cursor:default;
            background:map-get($colors, _01-05) !important;
        }
    }
}


/*
 * Gallery
 */

.woocommerce-product-gallery {
}
    .woocommerce-product-gallery__wrapper {
        @include flex-row;
        flex-wrap:wrap;
    }
        .woocommerce-product-gallery__image {
            width:100%;

            &:not(:first-child) {
                width:25%;
            }

            a {
                @include relative;
                display:block;

                img {
                    width:100%;
                }
            }
        }

/*
 * Colour Sample
 */

.colour-sample_wrapper {
    @include relative;
    padding:24px;
    background:#FFF;
}
    .colour-sample {
        @include relative;
        padding:48px;

        @media screen and (min-width:769px) {
            min-height:544px;
        }

        @media screen and (max-width:768px) {
            @include flex-column;
            justify-content:flex-end;
            padding:48px 24px 24px;
            min-height:calc(100vh - #{map-get($header_height, _mobile)});
            box-sizing:border-box;
        }

        .woocommerce-product-gallery__image {

            @media screen and (max-width:768px) {
                // display:none;
            }
        }

        .summary.entry-summary {

            @media screen and (min-width:769px) {
                @include absolute(2);
                left:48px; right:48px; bottom:48px;
            }
        }
    }
        .colour-sample_label {
            @include absolute(-1);
            top:24px; left:24px; right:24px; bottom:24px;
            border:1px solid #FFF;

            @media screen and (max-width:768px) {
                left:12px; right:12px; bottom:12px;
                border-color:rgba(#FFF, .12);
            }

            span {
                @include absolute;
                top:-9px; left:50%;
                padding:0 12px;
                color:#FFF;
                font-size:12px;
                font-weight:700;
                text-transform:uppercase;
                transform:translateX(-50%);
            }
        }

        .woocommerce-variation.single_variation {
            // display: flex !important;
            // height: 24px;
        }
            .wc-no-matching-variations.woocommerce-info {
                padding-bottom:18px;
                line-height:1.3;
            }

        .finish-chooser_trigger,
        .coverage-calculator_trigger {
            @include animate;
            display:inline-block;
            margin:6px 0 0 0;
            line-height:map-get($line_heights, _small);
            font-size:map-get($font_sizes, _small);
            color:map-get($colors, _03-01);
            cursor:pointer;
            text-decoration: underline;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _04-01) !important;
                }
            }
        }
        /*
        .finish-chooser_trigger {
            cursor: pointer;
            @include absolute;
            bottom: 232px;
            left: 24px;

            @media screen and (max-width:768px) {
                bottom: 312px;
                left: 48px;
            }
        }

        .coverage-calculator_trigger {
            cursor: pointer;
            @include absolute;
            bottom: 132px;
            left: 24px;

            @media screen and (max-width:768px) {
                bottom: 212px;
                left: 48px;
            }
        }
        */


/*
 * Add to Basket Form
 */

.variations {
    width:100%;
    border-collapse:collapse;

    tr {
        display:block;
        background:#FFF;
        border: 1px solid map-get($colors, _01-05);
        box-sizing:border-box;
        // margin-bottom: 36px;

        &:not(:first-child) {
            margin-top:6px;
        }

        th {
            &.label {
                margin-bottom:-9px; padding:9px 18px 0;
                font-size:map-get($font_sizes, _xsmall);
            }
        }

        td {
            display:block;

            &.label {
                margin-bottom:-9px; padding:9px 18px 0;
                line-height:map-get($line_heights, _xsmall);
                color:map-get($colors, _01-01);
                font-size:map-get($font_sizes, _xsmall);
            }
            &.value {
                @include woo-select;
            }

            .reset_variations {
                display:none !important;
            }
        }
    }
}

.single_variation_wrap {
    padding-top:6px;

    .woocommerce-variation {
        padding:6px 0 12px;

        .woocommerce-variation-availability {
            display: none;
            line-height:map-get($line_heights, _small);
            color:map-get($colors, _01-02);
            font-size:map-get($font_sizes, _small);
        }
    }
    .woocommerce-variation-add-to-cart {

        @media screen and (min-width:769px) {
            @include flex-row;
        }
    }
}


/*
 * White Shades
 */

.dark-form,
.product_tag-whites-shades {

    .colour-sample {

        &:before {
            @include absolute(-1);
            content:"";
            top:0; left:0; right:0; bottom:0;
            border:1px solid map-get($colors, _01-04);
        }

        .summary.entry-summary {
            color:#FFF;
            background:map-get($colors, _01-01);
        }
    }

    .colour-sample_label {
        border-color:map-get($colors, _01-04);

        span {
            color:map-get($colors, _01-01);
        }
    }

    .variations tr,
    .single_variation_wrap .quantity input {
        background:none;
        border-color:map-get($colors, _01-02);
    }

    .variations tr td.label,
    .variations tr td.value select,
    .single_variation_wrap .woocommerce-variation-price,
    .single_variation_wrap .quantity input {
        color:#FFF;
    }

    .single_variation_wrap .woocommerce-variation-availability {
        color:rgba(#FFF, .64);
    }

    .single_variation_wrap button.disabled {
        background:map-get($colors, _01-02) !important;
    }

    .finish-chooser_trigger,
    .coverage-calculator_trigger {
        color:map-get($colors, _04-01);

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _white) !important;
            }
        }

    }

    .variations tr td.value:after {
        border-color:#FFF;
    }
}


/*
 * Related Product
 */

.related-products {
    justify-content: center;
}


/*
 * Buy Now Form
 */

.wc--buy-now--form {
    @include animate;
    position:absolute;
    left:0; right:0; bottom:0;
    z-index:2;
    padding:12px;
    background:#FFF;

    @media screen and (max-width:768px) {
        left:24px; right:24px; bottom:24px;
    }

    &:not(.is-active) {
        opacity:0;
        visibility:hidden;
    }

    &--close {
        @include animate;
        @include absolute;
        @include flex-column;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        padding:0 !important;
        top:-12px !important; right:-12px;
        width:24px !important; min-width:unset !important; height:24px;
        font-size:0;
        color:rgba(#FFF, 0);
        text-indent:-999em;
        background:map-get($colors, _04-01) !important;
        border-radius:100%;
        overflow:hidden;

        @media screen and (max-width:768px) {
            margin-top:0 !important;
        }

        &:before,
        &:after {
            @include animate;
            @include absolute;
            content:"";
            top:12px; left:6px;
            width:12px; height:1px;
            background:#FFF;
        }
        &:before {
            transform:rotate(45deg);
        }
        &:after {
            transform:rotate(-45deg);
        }
    }
}

.colour-sample {

    .summary.entry-summary {
        padding:12px;

        button.wc--buy-now--btn-buy {
            width:100%;
            color:#FFF !important;
            background:map-get($colors, _01-01) !important;

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:#FFF !important;
                    background:map-get($colors, _04-01) !important;
                }
            }
        }

        button.wc--buy-now--btn-order {
            flex-shrink:inherit;
            padding:8px !important;
            width:100%;
            color:map-get($colors, _01-01) !important;
            font-size:12px;
            background:none !important;
            border:1px solid map-get($colors, _01-01);

            @media screen and (min-width:769px) {
                &:not(.disabled):not(:disabled):hover,
                &:not(.disabled):not(:disabled):focus {
                    color:map-get($colors, _04-01) !important;
                    background:none !important;
                    border:1px solid map-get($colors, _04-01);
                }
            }

            &.is_ajax-loading {
                padding-right:28px !important;
            }

            &:after {
                border-color:currentColor !important; border-top-color:transparent !important;
            }
        }

        .sample-buttons_info-bottom {
            padding:12px 12px 6px;
            font-size:14px;
            text-align:center;
        }

        .shipping-matrix {
            display: block;
            position: relative;
            padding:8px !important;
            color:map-get($colors, _01-01) !important;
            font-size:12px;
            background:none !important;
            border:1px solid map-get($colors, _01-01);
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;

            &:hover {
                color:map-get($colors, _04-01) !important;
                border:1px solid map-get($colors, _04-01);
            }
        }
    }
}

.dark-form,
.product_tag-whites-shades {

    .wc--buy-now--form {
        background:map-get($colors, _01-01);
    }

    .colour-sample {

        .summary.entry-summary {

            button.wc--buy-now--btn-buy {
                color:map-get($colors, _01-01) !important;
                background:#FFF !important;

                @media screen and (min-width:769px) {
                    &:not(.disabled):not(:disabled):hover,
                    &:not(.disabled):not(:disabled):focus {
                        color:#FFF !important;
                        background:map-get($colors, _04-01) !important;
                    }
                }
            }

            button.wc--buy-now--btn-order {
                color:#FFF !important;
                background:none !important;
                border:1px solid #FFF;

                @media screen and (min-width:769px) {
                    &:not(.disabled):not(:disabled):hover,
                    &:not(.disabled):not(:disabled):focus {
                        color:map-get($colors, _04-01) !important;
                        background:none !important;
                        border:1px solid map-get($colors, _04-01);
                    }
                }
            }

            .sample-buttons_info-bottom {
                color:#FFF;
            }

            .shipping-matrix {
                display: block;
                position: relative;
                padding:8px !important;
                color:map-get($colors, _white) !important;
                font-size:12px;
                background:none !important;
                border:1px solid map-get($colors, _white);
                text-transform: uppercase;
                font-weight: 600;
                text-align: center;

                &:hover {
                    color:map-get($colors, _04-01) !important;
                    border:1px solid map-get($colors, _04-01);
                }
            }
        }
    }
}

.wc--buy-now--order {
    padding-top:6px;

    button {
        margin-top:6px;
    }
}

// Screen Reader Fix
.screen-reader-text {
    clip:rect(1px,1px,1px,1px);
    word-wrap:normal!important;
    border:0;
    -webkit-clip-path:inset(50%);
    clip-path:inset(50%);
    height:1px;
    margin:-1px;
    overflow:hidden;
    overflow-wrap:normal!important;
    padding:0;
    position:absolute!important;
    width:1px
  }
  .screen-reader-text:focus {
    clip:auto!important;
    background-color:#fff;
    border-radius:3px;
    box-shadow:0 0 2px 2px rgba(0,0,0,.6);
    -webkit-clip-path:none;
    clip-path:none;
    color:#2b2d2f;
    display:block;
    font-size:.875rem;
    font-weight:700;
    height:auto;
    left:5px;
    line-height:normal;
    padding:15px 23px 14px;
    text-decoration:none;
    top:5px;
    width:auto;
    z-index:100000
  }
