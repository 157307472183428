/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: REVIEWS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_reviews {

    .pb-block_content {

        @media screen and (min-width:769px) {
            width:calc(100% + 192px);
        }
        @media screen and (max-width:1220px) and (min-width:769px) {
            width:calc(100% + 96px);
        }
        @media screen and (max-width:768px) {
            // width:calc(100% + 48px);
        }
    }
}
