/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.case-study_products {
    @include flex-row;
    margin:12px 0 0 0;
}

    .case-study_product-link {
        @include relative;
        display:block;
        padding:3px;
        border-radius:100%;
        background:linear-gradient(45deg, map-get($colors, _01-02) 0%, map-get($colors, _01-05) 40%, map-get($colors, _01-03) 60%, map-get($colors, _01-04) 100%);

        &:not(:last-child) {
            margin-right:6px;
        }
    }

    .case-study_product-image_wrapper {
        width:48px; height:48px;
        border-radius:100%;
        overflow:hidden;

        @media screen and (max-width:768px) {
            width:32px; height:32px;
        }

        .case-study_product-image {
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';
        }
    }

.case-study_heading {
    padding-top: 12px !important;
}
