/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: RELATED PRODUCTS --------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_related-products {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }
}
