/* ------------------------------------------------------------------------------------------------------------------------ */
/* NEWSLETTER ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * Form
 */

.form-newsletter {

    .form-fields_wrapper {
        padding-top:24px;

        @media screen and (min-width:769px) {
            @include flex-row;
        }
        @media screen and (max-width:960px) and (min-width:769px) {
            flex-wrap:wrap;
        }
        @media screen and (max-width:768px) {
            padding-top:6px;
        }
    }
        .field {
            border:none;

            @media screen and (min-width:769px) {
                flex:1 1 auto;
                margin-right:24px;
            }
            @media screen and (max-width:960px) and (min-width:769px) {
                &:not(:first-child) {
                    margin-right:0;
                }
            }
        }
}

    .form-newsletter_btn {
        margin-top:24px;

        @media screen and (max-width:960px) and (min-width:769px) {
            width:100%;
        }
        @media screen and (max-width:768px) {
            margin-top:18px;
            width:100%;
        }
    }
