/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRODUCT ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Related Products
 */

.related-products {
    margin-top:-24px;

    @media screen and (max-width:768px) {
        margin-top:-4px; margin-left:-2px !important; margin-right:-2px !important;
    }

    & > .column {

        @media screen and (max-width:1024px) and (min-width:769px) {
            width:(3 / 12) * 100%;
        }
        @media screen and (max-width:768px) {
            padding-left:2px !important; padding-right:2px !important;
            width:(4 / 12) * 100%;
        }
    }
}

/*
 * Attributes Accordion
 */

.attributes--accordion {
    padding-top:24px;

    &--list {
        list-style:none;
        margin-top:-12px;
    }

    &--trigger {
        @include animate;
        @include flex-row;
        align-items:center;
        line-height:map-get($line_heights, _small);
        color:map-get($colors, _01-01);
        font-size:map-get($font_sizes, _small);
        font-weight:700;
        text-transform:uppercase;

        & > * {
            pointer-events:none !important;
        }

        &:before {
            @include animate;
            content:"";
            margin-right:12px;
            width:0; height:0;
            border-left:6px solid transparent; border-right:6px solid transparent; border-top:6px solid map-get($colors, _01-01);
            transform:rotate(-90deg);
        }

        &:disabled {
            cursor:default !important;
        }

        @media screen and (min-width:769px) {
            &:not(.disabled):not(:disabled):hover,
            &:not(.disabled):not(:disabled):focus {
                color:map-get($colors, _04-01) !important;

                &:before {
                    border-top-color:map-get($colors, _04-01);
                }
            }
        }
    }

    &--block {
        padding-top:12px;

        &.open {

            .attributes--accordion--trigger:before {
                transform:rotate(0deg);
            }
        }
    }

    &--content {
        padding:6px 0 0 24px;
    }
}
