/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$colors: (
    _white: #FFF,
    _black: #000,
    _01-01: #4F4F49, // Darkest Gray
    _01-02: #878780, // Dark Gray
    _01-03: #A9A9A6, // Gray
    _01-04: #CFCABB, // Light Gray
    _01-05: #E6E2D5, // Lightest Gray
    _02-01: #373F32, // Dark Green
    _02-02: #768874, // Green
    _03-01: #4A457C, // Dark Purple
    _03-02: #584596, // Purple
    _04-01: #DD593B, // Orange
    _05-01: #BC9E61, // Gold
    _05-02: #F3B93E, // Yellow
    _06-01: #455A64, // Petrol Blue
    _error: #D91E18,
    _alert: #F89406,
    _valid: #00B16A,
    _update: #1E8BC3
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$widths: (
    _large: 1440px,
    _base: 1220px,
    _medium: 980px,
    _medium_small: 780px,
    _small: 680px,
    _xsmall: 480px
);

$header_height: (
    _desktop: 138px,
    _mobile: 74px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$line_heights: (
    _base: 1.6,
    _small: 1.5,
    _xsmall: 1.4
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$font_sizes: (
    _base: 18px,
    _small: 16px,
    _xsmall: 14px
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

$font_families: (
    _01: ('futura-pt', sans-serif),
    _02: ('futura-pt-bold', sans-serif)
);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1:unit($min-vw);
    $u2:unit($max-vw);
    $u3:unit($min-font-size);
    $u4:unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size:$min-font-size;
            @media screen and (min-width:$min-vw) {
                font-size:calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width:$max-vw) {
                font-size:$max-font-size;
            }
        }
    }
}
