/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: TEXT / MEDIA ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_text-media {

    .grid > .column:not(:first-child) {

        @media screen and (max-width:768px) {
            padding-top:48px;
        }
    }

    .wrapper-video,
    .media-image_wrapper {
        .lazyload-caption {
            padding-top: 3px;
        }
        @media screen and (min-width:1221px) {
            width:calc(100% + 96px);
        }
        @media screen and (max-width:1220px) and (min-width:769px) {
            width:calc(100% + 48px);
        }
    }

    .grid:not(.grid_reverse) .wrapper-video,
    .grid:not(.grid_reverse) .media-image_wrapper {

        @media screen and (min-width:1221px) {
            margin-left:-96px;
        }
        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-left:-48px;
        }
    }
    .grid.grid_reverse .wrapper-video,
    .grid.grid_reverse .media-image_wrapper {

        @media screen and (min-width:1221px) {
            margin-right:-96px;
        }
        @media screen and (max-width:1220px) and (min-width:769px) {
            margin-right:-48px;
        }
    }
}
