/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Navigation
 */

.nav-social {
    @include flex-row;
    justify-content:center;
    align-items:center;

    @media screen and (max-width:768px) {
        padding-top:48px;
    }

    &:before,
    &:after {

        @media screen and (min-width:769px) {
            content:"";
            flex:1 1 auto;
            height:1px;
            background:map-get($colors, _01-04);
        }
    }
}
    .nav-social_list {
        @include flex-row;
        list-style:none;
        margin:0 -12px;

        @media screen and (min-width:769px) {
            padding:0 48px;
        }
        @media screen and (max-width:768px) {
            justify-content:space-between;
            margin:0 -6px;
            width:calc(100% + 12px);
        }
    }
        .nav-social_item {
            padding:0 12px;

            @media screen and (max-width:768px) {
                padding:0 6px;
            }
        }
            .nav-social_link {
                @include flex-column;
                justify-content:center;
                align-items:center;
                width:48px; height:48px;
                border:1px solid map-get($colors, _01-04);
                border-radius:100%;
                box-sizing:border-box;

                svg {
                    @include animate;
                    display:block;
                    width:18px; height:18px;
                    fill:map-get($colors, _01-01);
                }

                @media screen and (min-width:769px) {
                    &:not(:disabled):hover,
                    &:not(:disabled):focus {

                        svg {
                            fill:map-get($colors, _04-01);
                        }
                    }
                }
            }


/*
 * Share
 */

.share-list {
    @include flex-row;
    list-style:none;
}
    .share-item {
        @include relative;
        overflow:hidden;

        &:not(:last-child) {
            margin-right:12px;
            padding-right:12px;
            border-right:1px solid rgba(#000, .12);
        }
    }
        .share-link {
            @include flex-column;
            @include relative;
            justify-content:center;
            align-items:center;
            width:24px; height:24px;
            background:map-get($colors, _01-05) !important;

            svg {
                @include animate;
                display:block;
                width:18px; height:18px;
                fill:map-get($colors, _01-01);
            }

            @media screen and (min-width:769px) {
                &:not(:disabled):hover,
                &:not(:disabled):focus {

                    svg {
                        fill:map-get($colors, _04-01);
                    }
                }
            }
        }

.share-modal_input {
    @include absolute(-1);
}
