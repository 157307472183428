/* ------------------------------------------------------------------------------------------------------------------------ */
/* ACCOUNT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

body.woocommerce-account.logged-in .woocommerce {

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content:space-between;
    }
}

    .woocommerce-MyAccount-navigation {

        @media screen and (min-width:769px) {
            flex-shrink:0;
            padding-right:96px;
            width:270px;
        }
    }

    .woocommerce-MyAccount-content {

        @media screen and (min-width:769px) {
            flex:1 1 auto;
        }
        @media screen and (max-width:768px) {
            padding-top:48px;
        }
    }


/*
 * LHC Navigation
 */

.woocommerce-MyAccount-navigation {

    ul {
        background:#FFF;
        border:1px solid map-get($colors, _01-04);
        list-style:none;

        li {

            &:not(:first-child) {
                border-top:1px solid map-get($colors, _01-04);
            }

            a {
                @include animate;
                display:block;
                padding:9px 18px;
                line-height:map-get($line_heights, _xsmall);
                color:map-get($colors, _01-01);
                font-size:map-get($font_sizes, _xsmall);
                text-transform:uppercase;

                @media screen and (min-width:769px) {
                    &:hover,
                    &:focus {
                        color:map-get($colors, _04-01);
                    }
                }
            }

            &.is-active a {
                color:map-get($colors, _04-01);
                cursor:default;
            }
        }
    }
}


/*
 * Generic Links
 */

.woocommerce-MyAccount-content p a {
    @include animate;
    color:map-get($colors, _01-01);
    text-decoration:underline;

    @media screen and (min-width:769px) {
        &:hover,
        &:focus {
            color:map-get($colors, _04-01);
            text-decoration:none;
        }
    }
}


/*
 * Addresses
 */

.woocommerce-Addresses,
.woocommerce-columns--addresses {
    padding-top:24px;

    @media screen and (min-width:769px) {
        @include flex-row;
        justify-content:space-between;
    }

    & > .woocommerce-Address,
    & > .woocommerce-column {
        padding:24px;
        border:1px solid map-get($colors, _01-04);
        box-sizing:border-box;

        @media screen and (min-width:769px) {
            width:calc(50% - 24px);
        }
        @media screen and (max-width:768px) {
            &:not(:first-child) {
                margin-top:12px;
            }
        }
    }

    a {
        @include woo-button;
        display:inline-block;
        margin:12px 0 24px;
        text-align:center;
    }

    address {
        line-height:map-get($line_heights, _xsmall);
        font-size:map-get($font_sizes, _small);
    }
}

.woocommerce-address-fields__field-wrapper + p > .button {
    margin-top:48px;
    width:100%;
}


/*
 * Orders
 */

.woocommerce-orders-table {
    margin-top:0 !important;
    width:100%;

    tr {

        @media screen and (max-width:768px) {
            flex-direction:column !important;

            &:last-child {
                padding-bottom:16px;
                border-bottom:1px solid map-get($colors, _01-04);
            }
        }
    }

    td {
        @media screen and (min-width:769px) {
            padding:12px 0 !important;
            vertical-align:middle !important;
        }
        @media screen and (max-width:768px) {
            @include flex-row;

            &:not(.woocommerce-orders-table__cell-order-actions):before {
                content:attr(data-title);
                width:100px;
                font-weight:700;
            }
        }
    }

    .woocommerce-orders-table__header-order-number,
    .woocommerce-orders-table__header-order-date,
    .woocommerce-orders-table__header-order-status,
    .woocommerce-orders-table__header-order-actions {
        width:17%;
    }
    .woocommerce-orders-table__header-order-total {
        width:32%;
    }

    .woocommerce-orders-table__cell-order-actions {

        @media screen and (max-width:768px) {
            padding-top:12px;
        }

        a {
            display:inline;
            padding:9px 18px !important;
            min-width:0 !important;
            text-align:center;

            &:not(:last-child) {
                margin-right:6px;
            }
        }
    }
}

.woocommerce-orders-table.woocommerce-MyAccount-orders {

    .woocommerce-orders-table__header-order-number,
    .woocommerce-orders-table__header-order-date,
    .woocommerce-orders-table__header-order-status,
    .woocommerce-orders-table__header-order-actions {
        width:17%;
    }
    .woocommerce-orders-table__header-order-total {
        width:32%;
    }
}

.woocommerce-account .woocommerce-order-details,
.woocommerce-account .woocommerce-customer-details {
    padding-top:48px;
}

.woocommerce-customer-details {
    padding-bottom: 48px;
}

.woocommerce-account .woocommerce-order-details {

    h2 {
        @include heading(1.3, 1.5, 32px, 24px);
        text-align:left;
    }

    .woocommerce-table--order-details {
        margin-top:24px;

        @media screen and (max-width:768px) {
            margin-top:8px;
        }

        th,
        td:first-child {
            flex:1 1 auto;
        }
    }
}


/*
 * Orders - Checkout
 */

body.woocommerce-order-pay {

    .shop_table {
        margin-top:0 !important;

        @media screen and (max-width:768px) {
            margin-top:-16px !important;
        }

        thead {

            th {
                width:33.33% !important;
            }
        }

        .wc-item-meta {
            list-style:none;
            font-weight:400;

            li {
                @include flex-row;
                flex-wrap:wrap;

                strong {
                    width:100px;
                    font-weight:400;
                }
                p {
                    width:calc(100% - 100px);
                }
            }
        }

        td.product-name {

            @media screen and (max-width:768px) {
                flex:1 1 auto;
            }
        }
        td.product-quantity {

            @media screen and (max-width:768px) {
                flex-shrink:0;
                padding:0 6px;
                width:36px;
            }
        }
        td.product-subtotal {

            @media screen and (max-width:768px) {
                flex-shrink:0;
                width:56px;
                text-align:right;
            }
        }

        tfoot th {

            @media screen and (max-width:768px) {
                flex:1 1 auto;
            }
        }
    }

    #payment .input-text {
        @include woo-input-text;
        height:auto;
        border:none !important;
    }
}


/*
 * Account Details
 */

.woocommerce-EditAccountForm {

    .form-row {

        &:not(:first-child) {
            padding-top:6px;
        }

        & > label:first-child:not(.woocommerce-form__label-for-checkbox) {
            @include relative(2);
            display:block;
            margin-bottom:-9px;
            padding:9px 18px 0;
            line-height:map-get($line_heights, _xsmall);
            color:map-get($colors, _01-01);
            font-size:map-get($font_sizes, _xsmall);
            background:#FFF;
        }
    }

    em {
        display:block;
        padding:6px 0 18px;
        line-height:map-get($line_heights, _small);
        font-size:map-get($font_sizes, _small);
    }

    fieldset {
        margin-top:24px;

        & > legend {
            font-weight:700;
        }
    }

    .button {
        margin-top:48px;
        width:100%;
    }
}

/*
 * Communication
 */

.aw-communication-page {
    .aw-communication-form__preference {
        .aw-communication-form__preference-inner {
            @include flex-row;
            padding-top:6px;
            line-height:1.2;

            .aw-communication-form__preference-text {
                padding-left: 0;
            }
        }
    }
}
