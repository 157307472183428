/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.woocommerce-cart,
body.woocommerce-checkout,
body.woocommerce-account {

/*
 * Checkbox / Radio
 */

input[type="checkbox"],
input[type="radio"] {
    @include relative;
    flex-shrink:0;
    margin-right:18px;
    width:32px; height:32px;
    box-sizing:border-box;
    background:none;
    border:0 solid;
    border-radius:0;
    cursor:pointer;
    appearance:none;

    &:before,
    &:after {
        @include absolute;
        content:"";
    }
    &:before {
        top:0; left:0;
        width:32px; height:32px;
        background:#FFF;
        border-color:#FFF;
    }
    &:after {
        @include animate;
        top:50%; left:50%;
        width:8px; height:8px;
        background:map-get($colors, _04-01);
        opacity:0;
        transform:translate(-50%, -50%);
    }

    &:checked:not(:disabled):after,
    &:checked:not(:disabled):after {
        opacity:1;
    }

    & + label {
        line-height:1.2;
    }
}

input[type="radio"] {

    &:after,
    &:before {
        border-radius:100%;
    }
}

.input-checkbox input {
    position:absolute !important;
    opacity:1 !important;

    & + span {
        background-color:#FFF !important;
        border-color:#FFF !important;
    }
}


/*
 * Select
 */

select.select2-hidden-accessible {
    display:none !important;
    opacity:0 !important;
    visibility:hidden !important;

    & + span {
        display:block;

        .select2-selection.select2-selection--single {
            margin:0; padding:12px 18px;
            height:auto;
            line-height:1.2;
            background:#FFF;
            border:none;
            border-radius:0;

            .select2-selection__rendered {
                display:inline;
                padding:0;
                line-height:1;
                color:map-get($colors, _01-01);
                font-family:map-get($font_families, _01);
                font-size:100%; font-size:16px;
                font-weight:700;
                white-space:normal;
            }
            .select2-selection__arrow {
                @include animate;
                @include absolute;
                top:17px; right:18px;
                width:6px; height:6px;
                border-right:2px solid map-get($colors, _01-01); border-bottom:2px solid map-get($colors, _01-01);
                transform:rotate(45deg);

                b {
                    display:none;
                }
            }
        }
    }
}

}
