/* ------------------------------------------------------------------------------------------------------------------------ */
/* INSPIRATION GALLERY ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.inspiration-gallery-slider {
    margin:0 auto;
    width:100%;

    @media screen and (max-width:768px) {
        order:-1;
        margin-bottom:48px;
    }

    .inspiration-gallery_caption {
        padding-top: 3px;
        text-align: center;
    }
}
