/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: FASTLINKS ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_fastlinks {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }
}
