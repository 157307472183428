/* ------------------------------------------------------------------------------------------------------------------------ */
/* FINISHES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb-block_finishes {
    .finish-chooser_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        span {
            padding-top: 24px;
            text-align: center;
        }
    }
}

.finishes {
}
    .finish {

        &:not(:first-child) {
            padding-top:48px;

            @media screen and (min-width:769px) {
                margin-top:48px;
                border-top:2px solid map-get($colors, _01-04);
            }
        }
    }

        .finish-text.column {

            @media screen and (max-width:768px) {
                padding:24px 24px 0 !important;
            }

            .typography {

                @media screen and (min-width:769px) {
                    padding-left:24px;
                    width:calc(100% - 24px); max-width:map-get($widths, _xsmall);
                }
            }
        }

        .finish-ctas.column {
            padding-top:48px;

            @media screen and (max-width:768px) {
                padding:24px 24px 0 !important;

                & + .finish-ctas.column {
                    padding-top:6px !important;
                }
            }

            .btn {
                width:100%;
                text-align:center;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        height: 24px;
                        width: auto;
                    }
                }
            }
        }
