/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.hero-slider {

    @media screen and (max-width:768px) {
        @include flex-row;
        flex-wrap:wrap;
        justify-content:center;
    }

    &.hero-slider-thin .hero-slide:before,
    &.hero-slider-short .hero-slide:before {
        @include aspect-ratio('634 / 1920'); // Aspect ratio: 3:1
    }
    &.hero-slider-tall .hero-slide:before {
        @include aspect-ratio('940 / 1920'); // Aspect ratio: 2:1
    }
    &.hero-slider-thin .hero-slide:before {

        @media screen and (max-width:768px) {
            @include aspect-ratio('800 / 960'); // Aspect ratio: 3:4
        }
    }
    &.hero-slider-short .hero-slide:before,
    &.hero-slider-tall .hero-slide:before {

        @media screen and (max-width:768px) {
            @include aspect-ratio('800 / 600'); // Aspect ratio: 3:4
        }
    }

    .swiper-wrapper {
        height:auto !important;

        @media screen and (max-width:768px) {
            margin-bottom:24px;
        }
    }

    & + .archive-products_filters {
        margin-top:48px;

        @media screen and (max-width:768px) {
            margin-top:24px;
        }
    }
}

.hero-slide {
    @include relative;
    @include flex-row;
    height:auto;

    @media screen and (min-width:769px) {
        align-items:center;
    }

    &.gradient-top:after,
    &.gradient-left:after,
    &.gradient-right:after,
    &.gradient-bottom:after,
    &.gradient-all-over:after {
        @include full-size;
        content:"";
        opacity:.64;
    }
    &.gradient-top:after {
        background:linear-gradient(to bottom, rgba(#000, 1) 0%, rgba(#000, 0) 50%);
        @media screen and (max-width:768px) {
            background:linear-gradient(to bottom, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
        }
    }
    &.gradient-left:after {
        background:linear-gradient(to right, rgba(#000, 1) 0%, rgba(#000, 0) 50%);
        @media screen and (max-width:768px) {
            background:linear-gradient(to right, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
        }
    }
    &.gradient-right:after {
        background:linear-gradient(to right, rgba(#000, 0) 0%, rgba(#000, 1) 50%);
        @media screen and (max-width:768px) {
            background:linear-gradient(to right, rgba(#000, 0) 0%, rgba(#000, 1) 100%);
        }
    }
    &.gradient-bottom:after {
        background:linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 1) 50%);
        @media screen and (max-width:768px) {
            background:linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 1) 100%);
        }
    }
    &.gradient-all-over:after {
        background:#000;
    }
}

    .hero-slide_content-wrapper {
        margin:0 auto; padding:64px 122px;
        width:100%; max-width:map-get($widths, _large);

        @media screen and (max-width:768px) {
            padding:48px 48px 48px 24px;
        }

        &.full-width {
            max-width: 100%;
        }
    }
        .hero-slide_content {
            @include relative(2);
            flex:1 1 auto;
            width:100%; max-width:map-get($widths, _xsmall);
        }
            .hero-slide_heading {
                text-wrap: balance;
            }
            .hero-slide_subheading {
                font-weight:400 !important;
                text-wrap: balance;
            }

    .hero-slide_bg {
        @include full-size(-1);
    }
        .hero-slide_bg-image {
            height:100% !important;
            object-fit:cover; font-family:'object-fit:cover;';

            @media screen and (max-width:768px) {
                object-position: center bottom;
            }
        }

.hero-slider_prev.slider-prev,
.hero-slider_next.slider-next {

    @media screen and (min-width:769px) {
        @include absolute(2);
        top:50%;
        transform:translateY(-50%);
    }

    svg {

        @media screen and (min-width:769px) {
            fill:#FFF;
        }
    }
}
.hero-slider_prev {

    @media screen and (min-width:769px) {
        left:48px;
    }
}
.hero-slider_next {

    @media screen and (min-width:769px) {
        right:48px;
    }
}
