/* ------------------------------------------------------------------------------------------------------------------------ */
/* MIXINS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@mixin woo-input-text {
    padding:9px 18px;
    width:100%; height:100%;
    line-height:map-get($line_heights, _base);
    color:map-get($colors, _01-01);
    font-family:map-get($font_families, _01);
    font-size:100%; font-size:16px;
    font-weight:700;
    background:#FFF;
    border: 1px solid map-get($colors, _01-05);
    box-sizing:border-box;
    appearance:none;
}

@mixin woo-select {
    @include relative;

    select {
        @include animate;
        @include relative;
        margin:0; padding:9px 18px;
        width:100%; height:auto;
        line-height:map-get($line_heights, _base);
        color:map-get($colors, _01-01);
        font-family:map-get($font_families, _01);
        font-size:100%; font-size:16px;
        font-weight:700;
        box-sizing:border-box;
        background:none;
        border:0;
        border-radius:0;
        appearance:none;
    }

    &:after {
        @include animate;
        @include absolute(-1);
        content:"";
        top:17px; right:18px;
        width:6px; height:6px;
        border-right:2px solid map-get($colors, _01-01); border-bottom:2px solid map-get($colors, _01-01);
        transform:rotate(45deg);
    }
}

@mixin woo-button {
    @include animate;
    flex-shrink:0;
    padding:16px 28px;
    min-width:128px;
    line-height:map-get($line_heights, _small);
    color:#FFF;
    font-size:map-get($font_sizes, _small);
    font-weight:700;
    text-transform:uppercase;
    background:map-get($colors, _04-01) !important;
    box-sizing:border-box;

    @media screen and (min-width:769px) {
        &:not(.disabled):not(:disabled):hover,
        &:not(.disabled):not(:disabled):focus {
            background:darken(map-get($colors, _04-01), 12%) !important;
        }
    }

    &.disabled,
    &:disabled {
        cursor:default;
        background:map-get($colors, _01-03) !important;
    }

    &.is_ajax-loading {
        padding-right:48px;
    }
}
