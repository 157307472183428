/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: FAQS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_faqs {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }
}
