/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.woocommerce-main {
}
    .woocommerce-main_content {
    }
    .woocommerce-main_sidebar {
    }

.page-builder > .woocommerce {

    @media screen and (max-width:768px) {
        padding:0 24px;
    }
}
