/* ------------------------------------------------------------------------------------------------------------------------ */
/* CART ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

body.woocommerce-cart .shop_table:first-child {
    margin-top:0;
}


/*
 * Table
 */

.woocommerce-cart-form__contents {
    width:100%;
    border-collapse:collapse; border-spacing:0;

    @media screen and (max-width:768px) {
        display:block;
        padding-top:16px;
        border-top:2px solid map-get($colors, _01-04);
    }

    thead {

        @media screen and (max-width:768px) {
            display:none;
        }
    }

    th {
        padding-bottom:9px;
        line-height:map-get($line_heights, _small);
        font-size:24px;
        font-weight:700;
        font-family:map-get($font_families, _01);
        text-align:left;
        border-bottom:2px solid map-get($colors, _01-04);

        @media screen and (max-width:768px) {
            line-height:map-get($line_heights, _xsmall);
            font-size:map-get($font_sizes, _base);
        }

        &.product-remove {
            width:0;
            font-size:0;
        }
        &.product-thumbnail {
            width:120px;
        }
        &.product-price,
        &.product-subtotal {
            width:144px;
        }
        &.product-quantity {
            width:200px;
        }
    }
}


/*
 * Item
 */

.woocommerce-cart-form__cart-item {

    @media screen and (max-width:768px) {
        @include flex-row;
        flex-wrap:wrap;
        margin-bottom:16px; padding-bottom:16px;
        border-bottom:2px solid map-get($colors, _01-04);
    }

    &:not(:last-child) td:not(.product-remove) {

        @media screen and (min-width:769px) {
            padding-bottom:24px;
            border-bottom:1px solid map-get($colors, _01-04);
        }
    }

    td {

        @media screen and (min-width:769px) {
            padding-top:24px;
            vertical-align:top;
        }
    }

    .product-remove {
        @include relative;

        a {
            @include animate;
            @include absolute;
            @include flex-column;
            justify-content:center;
            align-items:center;
            flex-shrink:0;
            top:12px; left:-12px;
            width:24px; height:24px;
            font-size:0;
            color:rgba(#FFF, 0);
            text-indent:-999em;
            background:map-get($colors, _01-04);
            border-radius:100%;
            overflow:hidden;

            @media screen and (max-width:768px) {
                top:-12px;
            }

            &:before,
            &:after {
                @include animate;
                @include absolute;
                content:"";
                top:12px; left:6px;
                width:12px; height:1px;
                background:map-get($colors, _01-01);
            }
            &:before {
                transform:rotate(45deg);
            }
            &:after {
                transform:rotate(-45deg);
            }

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    background:map-get($colors, _04-01);

                    &:before,
                    &:after {
                        background:#FFF;
                    }
                }
            }
        }
    }

    .product-thumbnail {

        a,
        a img {
            display:block;
            width:96px; height:96px;

            @media screen and (max-width:768px) {
                width:72px; height:72px;
            }
        }
    }

    .product-name {

        @media screen and (max-width:768px) {
            padding-left:16px;
            width:calc(100% - 112px);
        }

        a {
            @include animate;
            color:map-get($colors, _01-01);
            font-weight:700;

            @media screen and (min-width:769px) {
                &:hover,
                &:focus {
                    color:map-get($colors, _04-01);
                    text-decoration:underline;
                }
            }
        }
    }

    .product-price,
    .product-quantity,
    .product-subtotal {

        @media screen and (max-width:768px) {
            margin-top:16px; padding-top:16px;
            border-top:1px solid map-get($colors, _01-04);

            &:before {
                content:attr(data-title);
                display:block;
            }
        }
    }

    .product-price,
    .product-subtotal {

        .woocommerce-Price-amount {
            display:block;
            padding-top:28px;

            @media screen and (max-width:768px) {
                padding-top:14px;
            }
        }
    }

    .product-price {

        @media screen and (max-width:768px) {
            width:72px;
        }
    }

    .product-quantity {

        @media screen and (min-width:769px) {
            vertical-align:middle;
            transform:translateY(-5px);
        }
        @media screen and (max-width:768px) {
            padding-left:16px; padding-right:16px;
            width:84px;

            &:before {
                margin-bottom:14px;
            }
        }

        input {
            @include woo-input-text;
            max-width:120px;
            border:none !important;

            @media screen and (min-width:769px) {
                // margin-top:19px;
            }
            @media screen and (max-width:768px) {
                margin-top:-8px;
            }
        }
    }

    .product-subtotal {

        @media screen and (max-width:768px) {
            flex:1 1 auto;
        }
    }
}


/*
 * Variations
 */

.variation {
    @include flex-row;
    flex-wrap:wrap;

    dt {
        width:100px;
    }
    dd {
        width:calc(100% - 100px);
    }
}


/*
 * Actions
 */

.woocommerce-cart-form__contents .actions {
    padding-top:48px;

    @media screen and (max-width:768px) {
        padding-top:32px;
    }

    .coupon {

        @media screen and (min-width:769px) {
            @include flex-row;
            float:left;
        }

        label {

            @media screen and (min-width:769px) {
                align-self:center;
                padding-right:24px;
            }
            @media screen and (max-width:768px) {
                display:block;
            }
        }
        input {
            @include woo-input-text;
            height:auto;
            border:none !important;

            @media screen and (min-width:769px) {
                margin-right:6px;
                max-width:200px;
            }
            @media screen and (max-width:768px) {
                margin-top:6px;
            }
        }
    }

    .coupon + .button {

        @media screen and (min-width:769px) {
            float:right;
        }
        @media screen and (max-width:768px) {
            margin-top:48px;
        }
    }

    .button {
        @include woo-button;

        @media screen and (max-width:768px) {
            margin-top:6px;
            width:100%;
        }
    }
}

.blockOverlay {
    background-color:rgba(map-get($colors, _01-05), .88) !important;
}


/*
 * Totals
 */

.cart_totals {
    padding-top:96px;

    @media screen and (max-width:1220px) {
        padding-top:48px;
    }

    h2 {
        text-align:center;
    }

    table {
        margin-top:24px;
        width:100%;
        border-collapse:collapse; border-spacing:0;

        tr {

            @media screen and (max-width:768px) {
                @include flex-row;

                &.shipping {
                    flex-direction:column;
                }

                &:not(:first-child) {
                    margin-top:16px; padding-top:16px;
                    border-top:1px solid map-get($colors, _01-04);
                }
            }
        }

        th {
            width:160px;
            text-align:left;
        }

        th,
        td {

            @media screen and (min-width:769px) {
                padding:24px 0;
                border-bottom:1px solid map-get($colors, _01-04);
                vertical-align:top;
            }
        }
    }

    .woocommerce-shipping-destination {
        padding-top:24px;
    }

    .checkout-button {
        @include woo-button;
        display:block;
        margin-top:48px;
        text-align:center;
    }
}


/*
 * Shipping Methods
 */

.woocommerce-shipping-methods {
    list-style:none;

    @media screen and (max-width:768px) {
        padding-top:9px;
    }

    li {
        @include flex-row;
        align-items:center;

        &:not(:first-child) {
            padding-top:6px;
        }

        & > label {
            cursor:pointer;
        }
    }
}


/*
 * Shipping Calculator
 */

.woocommerce-shipping-calculator {
    padding-top:24px;

    .shipping-calculator-button,
    button {
        @include woo-button;
        display:inline-block;
    }
    .shipping-calculator-button {
        padding:9px 18px !important;
    }
    button {
        margin-top:24px;
    }

    .shipping-calculator-form {

        .form-row {
            padding-top:6px;
            width:100%;

            &:first-child {
                padding-top:24px;
            }

            input {
                @include woo-input-text;
                border:none !important;
            }
        }
    }
}


/*
 * Empty Cart
 */

.cart-empty.woocommerce-info {
    text-align:center;
}
    .return-to-shop {
        padding-top:32px;
        text-align:center;

        .button {
            @include woo-button;
            display:inline-block;
        }
    }

.woocommerce-message + .cart-empty.woocommerce-info {
    margin-top:48px;
}
