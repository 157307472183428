/* ------------------------------------------------------------------------------------------------------------------------ */
/* ORDER SAMPLES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.order-samples {
    margin-top:24px;

    @media screen and (max-width:768px) {
        margin-top:12px; margin-left:-6px !important; margin-right:-6px !important; padding-left:24px; padding-right:24px;
    }

    & > .column {

        @media screen and (max-width:1024px) and (min-width:769px) {
            width:(3 / 12) * 100%;
        }
        @media screen and (max-width:768px) {
            padding-left:6px !important; padding-right:6px !important;
            width:(6 / 12) * 100%;
        }
    }
}
