/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: INSPIRATION GALLERY GRID ------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_inspiration-gallery-grid {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }

    .pb-block_content {
        @include flex-row;
        align-items:center;

        @media screen and (max-width:768px) {
            flex-wrap:wrap;
            justify-content:center;
        }

        .inspiration-gallery-grid {
            width: 100%;

            .inspiration-gallery-grid-item {
                margin-bottom: 24px;

                .inspiration-gallery-image_wrapper {
                    position: relative;
                    padding-top: 100%;

                    .inspiration-gallery-image {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }
    }
}
