.video-hero {
    width: 100%;
    &__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &--desktop {
        }
        &--mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 768px) {
        &__player {
            &--desktop {
                display: none;
            }
            &--mobile {
                display: block;
            }
        }
    }
}

.vimeo-iframe {
    &__sound {
        position: absolute;
        bottom: 20px;
        right: 20px;
        background-color: map-get($colors, _white);
        fill: map-get($colors, _01-01);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
        transition: all 0.2s ease-in-out;
        &--muted {
            display: none;
        }
        &.is-muted {
            .vimeo-iframe__sound--playing {
                display: none;
            }
            .vimeo-iframe__sound--muted {
                display: block;
            }
        }
    }
    &__play {
        position: absolute;
        bottom: 20px;
        right: 70px;
        background-color: map-get($colors, _white);
        fill: map-get($colors, _01-01);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
        transition: all 0.2s ease-in-out;
        &--paused {
            display: none;
        }
        &.is-playing {
            .vimeo-iframe__play--playing {
                display: none;
            }
            .vimeo-iframe__play--paused {
                display: block;
            }
        }
    }
}
