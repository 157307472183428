/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE HEADING ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_page-heading {

    &.pb-block_has-bg {

        .pb-block_content .typography {
            width:100%; max-width:map-get($widths, _xsmall);
        }

        .pb-block_background:before {
            @include full-size(2);
            content:"";
            opacity:.64;
            background:linear-gradient(to right, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
        }
    }

    &:not(.pb-block_has-bg) .pb-block_content {

        @media screen and (min-width:769px) {
            @include flex-row;
            align-items:center;
        }

        .typography:not(:only-child) {

            @media screen and (min-width:1221px) {
                padding-right:96px;
            }
            @media screen and (max-width:1220px) and (min-width:769px) {
                padding-right:48px;
            }
        }
        .page-heading_logo-wrapper {
            width:100%;

            @media screen and (min-width:769px) {
                max-width:280px; min-width:180px;
            }
            @media screen and (max-width:768px) {
                margin-top:24px;
            }
        }
    }

    & + .pb-block_text {
        padding-top:48px; padding-bottom:48px;
    }
}
