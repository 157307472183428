/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: COLOUR SCHEMES ----------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_colour-schemes {

    @media screen and (min-width:769px) {
        padding-left:0; padding-right:0;
    }

    .pb-block_content {
        @include flex-row;
        align-items:center;

        @media screen and (max-width:768px) {
            flex-wrap:wrap;
            justify-content:center;
        }
    }
}
