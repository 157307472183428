/* ------------------------------------------------------------------------------------------------------------------------ */
/* PRELOAD ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.preload * {
    transition: none !important;
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.wrapper {
    @include animate;
    @include flex-column;
    flex-shrink:0;
    margin-top:map-get($header_height, _desktop);
    min-height:calc(100vh - #{map-get($header_height, _desktop)});
    overflow:hidden;

    @media screen and (min-width:769px) {
        padding:0 24px;
    }
    @media screen and (max-width:1259px) and (min-width:769px) {
        margin-top:168px;
        min-height:calc(100vh - 168px);
    }
    @media screen and (max-width:768px) {
        margin-top:map-get($header_height, _mobile); margin-bottom:24px;
        min-height:calc(100vh - #{map-get($header_height, _mobile)});
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.main-container {
    @include flex-column;
    flex:1 1 auto;
    margin:0 auto;
    width:100%; max-width:map-get($widths, _large);
}

.container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE CONTROLLER OVERRIDES -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.cc-toggle-label {
    display:none !important;
}

.cc-btn.cc-btn-bare {
    background:none !important;
}
