/* ------------------------------------------------------------------------------------------------------------------------ */
/* CONTACT ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/*
 * Google Map
 */

.gmap {
    @include relative;
    @include flex-row;
    min-height:map-get($widths, _xsmall);

    &:before {
        @include aspect-ratio('940 / 1920'); // Aspect ratio: 2:1
    }

    iframe {
        @include full-size(-1);
        display:block;
        width:100%; height:100%;
        border:none;
    }
}


/*
 * Form
 */

.form-contact {
    margin-top:-12px !important;

    .field {
        border:none !important;

        &.field_no-border {
            padding-top:12px !important;
        }
        &:not(.field_no-border) {
            margin-top:12px !important;
        }
    }

    .input-checkbox input + span {
        background:#FFF;
        border-color:#FFF;
    }
}
