/* ------------------------------------------------------------------------------------------------------------------------ */
/* PB BLOCK: COVERAGE CALCULATOR ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.pb-block_finish-chooser {
    padding-top:48px; padding-bottom:48px;
    border-top:1px solid map-get($colors, _01-04); border-bottom:1px solid map-get($colors, _01-04);

    .finish-chooser_icon {
        width: 50px;
        height: auto;
        margin-bottom: 12px;
    }

    .finish-chooser_info {
        text-align: center;
        padding-bottom: 24px;
    }

    .btn {
        width:100%; max-width:map-get($widths, _xsmall);
    }
}
