/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.js-modal_wrapper {
    transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    position:fixed;
    top:0px; left:0px; right:0px; bottom:0px;
    z-index:-1;
    background:rgba(map_get($colors, _01-05), .95);
    opacity:0;
    visibility:hidden;

    @media screen and (max-width:768px) {
        background:#FFF;
        overflow:auto;
    }

    .js-modal_content {

        @media screen and (min-width:769px) {
            &:not(.box) {
                position:absolute;
                top:50%; left:50%;
                z-index:2;
                width:calc(100% - 96px);
                transform:translate(-50%, -50%);

                &:before,
                &:after {
                    content:"";
                    display:block;
                    width:100%; height:48px;
                }
            }
        }
    }

    .js-modal_trigger {
    }

    &.fixedHeight {

        @media screen and (min-width:769px) {
            overflow:auto;

            .js-modal_content {
                @include relative;
                top:auto; left:auto;
                margin:0 auto;
                transform:none;
            }
        }
    }

    &.open {
        z-index:99998;
        opacity:1;
        visibility:visible;
    }
}


/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.js-modal_trigger_no-js {
    display:none !important;
    opacity:0 !important;
    visibility:hidden !important;
}
.no-js {

    .js-modal_wrapper {
        z-index:1;

        &:target {
            z-index:99999;
            opacity:1;
            visibility:visible;
        }
    }

    .js-modal_trigger_no-js {
        display:block !important;
        opacity:1 !important;
        visibility:visible !important;

        & + a {
            display:none !important;
            opacity:0 !important;
            visibility:hidden !important;
        }
    }
}
