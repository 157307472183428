.shipping-banner {
    .shipping-banner_container {
        padding: 12px 24px;

        .typography.color_white {
            a {
                color: #ffffff !important;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
