/* ------------------------------------------------------------------------------------------------------------------------ */
/* PANEL ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.js-panel-trigger {
    @include relative;

    &:after {
        @include absolute;
        content:"";
        left:0; right:0; bottom:-16px;
        height:16px;
    }

    &:not(.is-disabled):hover > .js-panel,
    &:not(.is-disabled):focus > .js-panel {
        opacity:1;
        visibility:visible;
        transform:translateY(0);
    }
}

.js-panel {
    @include absolute(2);
    top:35px; right:-19px;
    padding:24px;
    width:calc(100% + 38px); min-width:300px; max-width:312px;
    background:map-get($colors, _01-05);
    box-shadow:0 3px 48px 0 rgba(#000, .12);
    box-sizing:border-box;
    opacity:0;
    visibility:hidden;
    transform:translateY(6px);
    transition:opacity 300ms ease-in-out, visibility 300ms ease-in-out, transform 300ms ease-in-out;

    &.is-open {
        opacity:1;
        visibility:visible;
        transform:translateY(0);
    }
}
