/* ------------------------------------------------------------------------------------------------------------------------ */
/* FAQ -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.faqs-list,
.faqs-group_list {
    list-style:none;
}

.faqs-group.open .faqs-group_heading > .faqs-chevron svg,
.faqs-item.open .faqs-question > .faqs-chevron svg {
    transform:rotate(-90deg);
}

// .faqs-group:not(.open) .faqs-group_container,
// .faqs-item:not(.open) .faqs-answer {
//     display:none;
// }

.faqs-group_heading,
.faqs-question {
    @include flex-row;
    justify-content:space-between;
    align-items:center;
    cursor:pointer;
}
    .faqs-chevron {
        @include animate;
        @include flex-column;
        justify-content:center;
        align-items:center;
        flex-shrink:0;
        margin-left:24px;
        width:32px; height:32px;
        background:map-get($colors, _01-01);
        border-radius:100%;

        svg {
            @include animate;
            display:block;
            width:12px; height:12px;
            fill:#FFF;
            transform:rotate(90deg);
        }
    }

.faqs-list {
    margin-top:-48px;
}
    .faqs-group {
        padding-top:48px;
    }
        .faqs-group_heading {
            padding-bottom:12px;
            border-bottom:2px solid map-get($colors, _01-01);
        }

.faqs-item {
    margin-top:24px; padding:18px 0;
    border-top:1px solid map-get($colors, _01-04); border-bottom:1px solid map-get($colors, _01-04);
}
    .faqs-question .faqs-chevron {
        background:none;
        border:2px solid map-get($colors, _01-01);
        box-sizing:border-box;

        svg {
            fill:map-get($colors, _01-01);
        }
    }
    .faqs-answer_content {
        padding-top:12px;
    }
