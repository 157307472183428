/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.cookie-notice {
    @include animate;
    position:fixed;
    left:24px; bottom:24px;
    padding:24px;
    line-height:1.4;
    color:map-get($colors, _01-01);
    font-size:14px;
    background:#FFF;
    box-shadow:0 3px 12px 0 rgba(#000, .12);

    @media screen and (min-width:769px) {
        z-index:99999;
        width:100%; max-width:200px;
        text-align:center;
    }
    @media screen and (max-width:768px) {
        @include flex-row;
        flex-wrap:wrap;
        align-items:center;
        z-index:99998;
        left:12px; right:12px; bottom:12px;
    }

    &.hide {
        opacity:0;
        visibility:hidden;
    }
}

    .cookie-notice_icon {
        margin:0 auto; padding-bottom:12px;
        width:48px; height:48px;

        @media screen and (max-width:768px) {
            margin:0; padding:0 18px 0 0;
        }

        svg {
            display:block;
            width:48px; height:48px;
            fill:map-get($colors, _01-01);
        }
    }

    .cookie-notice_message > a {
        @include animate;
        color:map-get($colors, _01-01);
        text-decoration:underline;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _01-01);
                text-decoration:none;
            }
        }
        @media screen and (max-width:768px) {
            flex:1;
        }
    }

    .cookie-notice_close-anchor {
        @include animate;
        display:inline-block;
        margin:12px auto 0;
        color:map-get($colors, _01-01);
        font-weight:500;
        text-decoration:none;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                color:map-get($colors, _01-01);
            }
        }
        @media screen and (max-width:768px) {
            flex:1 0 auto;
            margin:18px -24px -24px; padding:16px 0;
            width:100%;
            text-align:center;
            border-top:1px solid #EEE;
        }
    }

    .cookie-notice_close-icon {
        @include animate;
        @include absolute;
        top:12px; right:12px;
        padding:4px;
        width:16px; height:16px;
        opacity:.24;
        box-sizing:content-box;

        @media screen and (min-width:769px) {
            &:hover,
            &:focus {
                opacity:1;
            }
        }
        @media screen and (max-width:768px) {
            top:0; right:0;
            padding:12px;
            width:12px; height:12px;
        }

        svg {
            display:block;
            width:16px; height:16px;
            fill:map-get($colors, _01-01);

            @media screen and (max-width:768px) {
                width:12px; height:12px;
            }
        }
    }
